import { Button, ButtonProps, Typography, TypographyOwnProps } from '@mui/material';

type Props = ButtonProps & {
  children: React.ReactNode;
  typographyProps?: TypographyOwnProps;
};

export default function CaptionButton({ children, sx, typographyProps, ...rest }: Props) {
  // minWidth приходится сбрасывать, у Button есть
  return (
    <Button variant="text" sx={{ p: 0, minWidth: 'inherit', textAlign: 'left', ...sx }} {...rest}>
      <Typography variant="caption" {...typographyProps}>
        {children}
      </Typography>
    </Button>
  );
}
