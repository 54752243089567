import { Link, Typography } from '@mui/material';
import { Box, Card, Stack } from '@mui/material';
import Label from 'components/ui/info/Label';
import Scrollbar from 'components/ui/Scrollbar';
import { format } from 'date-fns';
import useIsMobile from 'hooks/useIsMobile';
import useLocales from 'hooks/useLocales';
import React, { useMemo } from 'react';
type ReleaseNote = {
  title: string;
  isNew: boolean;
  date: Date;
  href: string;
};

function ReleaseNotesBlock() {
  const { translate } = useLocales();
  const isMobile = useIsMobile();
  const releaseNotes: ReleaseNote[] = useMemo(
    () => [
      {
        date: new Date('2025-01-15'),
        href: 'https://confluence.marya.ru/pages/viewpage.action?pageId=36144613',
        isNew: true,
        title: translate('pages.login.releaseNotes.1-3-1'),
      },
      {
        date: new Date('2025-01-15'),
        href: 'https://confluence.marya.ru/pages/viewpage.action?pageId=36143884',
        isNew: false,
        title: translate('pages.login.releaseNotes.1-3-0'),
      },
      {
        date: new Date('2025-01-15'),
        href: 'https://confluence.marya.ru/pages/viewpage.action?pageId=36143881',
        isNew: false,
        title: translate('pages.login.releaseNotes.1-2-9'),
      },
      {
        date: new Date('2025-01-15'),
        href: 'https://confluence.marya.ru/pages/viewpage.action?pageId=36143879',
        isNew: false,
        title: translate('pages.login.releaseNotes.1-2-8'),
      },
    ],
    []
  );

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Stack mb={{ xs: 2.5, md: 3.5 }} direction="row" justifyContent={{ xs: 'center', md: 'space-between' }} alignItems="center">
        <Typography variant="h4">{translate('pages.login.updates')}</Typography>
        {!isMobile && (
          <Link
            sx={{ mr: 2, display: { xs: 'hidden', md: 'block' } }}
            component={'a'}
            variant="subtitle2"
            target="_blank"
            href="https://confluence.marya.ru/pages/viewpage.action?pageId=36143874"
          >
            {translate('pages.login.showAll')}
          </Link>
        )}
      </Stack>

      <Scrollbar sx={{ height: { md: 592 }, pr: { xs: 0, md: 2 }, pb: { xs: 3, md: 0 } }}>
        <Stack spacing={2} pb={2}>
          {releaseNotes.map((note) => (
            <ReleaseNoteListItem releaseNote={note} key={note.title} />
          ))}
        </Stack>
      </Scrollbar>
    </Box>
  );
}

function ReleaseNoteListItem({ releaseNote }: { releaseNote: ReleaseNote }) {
  const { isNew, date, href, title } = releaseNote;

  return (
    <Card
      sx={(theme) => ({
        py: 1.5,
        px: 2,
        '&:hover': { background: theme.palette.action.hover },
        transition: theme.transitions.create('background-color'),
        cursor: 'pointer',
      })}
      onClick={() => window.open(href, '_blank', 'noopener,noreferrer')}
    >
      <Box>
        <Box>
          <Typography fontWeight={500} variant="subtitle1">
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color="text.secondary" variant="body2">
            {format(date, 'dd.MM.yyyy')}
          </Typography>
          {isNew && <Label color="primary">New</Label>}
        </Box>
      </Box>
    </Card>
  );
}

export default React.memo(ReleaseNotesBlock, () => true);
