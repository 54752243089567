import { Box, Chip, Divider, Stack, StackProps, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useDI from 'hooks/useDI';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'storage';
import SpoilerPlusIconButton from 'components/ui/buttons/SpoilerPlusIconButton';
import FormProvider from 'components/ui/forms/FormProvider';
import AmountInputMemorized from 'components/ui/forms/AmountInputMemorized';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import { LoadingButton } from '@mui/lab';
import ModelUtils from 'utils/models/ModelUtils';
import { useFormik } from 'formik';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';
import useIsMobile from 'hooks/useIsMobile';
import useLocales from 'hooks/useLocales';
import * as Yup from 'yup';
import IPermissionService from 'services/permission/IPermissionService';
import ILanguageService from 'services/language/ILanguageService';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';
import NumberUtils from 'utils/Number';
import StringUtils from 'utils/String';
import { ServiceOrderedValueWithDiscount } from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedValueWithDiscount';
import { ServiceOrderedDiscountInfoBlock } from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedDiscountInfoBlock';
import Spoiler from 'components/ui/info/Spoiler';
import ServiceOrderedDetailsBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedDetailsBlock';
import CheckboxMemorized from 'components/ui/forms/CheckboxMemorized';
import TimeInputMemorized from 'components/ui/forms/dateTime/TimeInputMemorized';
import OrderSessionServiceItemEditButtonGroup from 'components/_dashboardPagesFeatures/order/add/session/services/add/OrderSessionServiceItemEditButtonGroup';
import { PRICE_VALUE_TYPE } from '../../../../../../../typings/models/price.enum';
import PriceWithTooltip from '../../../../serviceOrdered/PriceWithTooltip';

type OrderServiceItemSessionEditBlockProps = StackProps & {
  price: Price;
  orderSession: OrderContractSessionData | null;
  submitHandlerNoNavigate: (dto: CreateServiceOrderedRequestDTO) => any | Promise<any>;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  currency: ILanguageService.CurrencyCode;
  permissionConfig: IPermissionService.PermissionConfigs;
  closeAddBlock: VoidFunction;
  isOpened: boolean;
  setOpenedBlockId: (number: string | null) => void;
  deleteHandler: (serviceId: string) => void;
  duplicateAndOpenBlockId: (number: string | null) => void;
  totalQuantity?: number;
};

function OrderSessionServiceItemEditBlock({
  price,
  closeAddBlock,
  submitHandlerNoNavigate,
  fullWidth,
  sx,
  orderSession,
  currency,
  permissionConfig,
  isOpened,
  setOpenedBlockId,
  deleteHandler,
  duplicateAndOpenBlockId,
  totalQuantity,
  ...rest
}: OrderServiceItemSessionEditBlockProps) {
  const { translate } = useLocales();
  const handleFormErrors = useSnackbarErrorHandler();
  const { services, statefulUtils } = useDI();
  const [duplicated, setDuplicated] = useState(false);

  const baseService = useMemo(() => {
    return ServiceOrderedUtils.priceToServiceOrdered(price);
  }, [price.updatedAt.getTime()]);
  const [isCreated, setIsCreated] = useState(false);
  const setNotCreatedHandler = () => setIsCreated(false);
  const { permissions } = useSelector((state) => state.auth);
  const isMobile = useIsMobile();

  const [serviceOrdered, canDeleteCatalogService] = useMemo(() => {
    const serviceOrdered = orderSession ? orderSession.services.filter((service) => service.catalogId === price.service.id)[0] : null;
    const canDeleteCatalogService = permissions.serviceOrderedSessionBusinessPermissionCheck(
      FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_FROM_CATALOG,
      serviceOrdered,
      orderSession,
      permissionConfig
    );

    return [serviceOrdered, canDeleteCatalogService];
  }, [orderSession?.updatedAt.getTime(), orderSession?.id]);

  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const valueIsTooLowText = translate('errors.valueIsTooSmall');

  const [validationSchema, initialValues] = useMemo(() => {
    const validationSchema = Yup.object().shape({
      service: Yup.mixed().test({ message: fieldIsRequiredText, test: (v) => Boolean(v) }),
      name: Yup.string().trim(fieldIsRequiredText).required(fieldIsRequiredText),
      included: Yup.string(),
      notIncluded: Yup.string(),
      quantity: Yup.number().min(1, valueIsTooLowText).required(fieldIsRequiredText),
      priceClientValue: Yup.number()
        .min(0, valueIsTooLowText)
        .test({
          message: translate('entities.pricelist.currencyDecimalNumberExceededValidationError'),
          test: (v) => NumberUtils.getDecimalCount(v || 0) <= 2,
        })
        .required(fieldIsRequiredText),
      isWorkEstimationCustom: Yup.boolean(),
      workEstimateMin: Yup.number().test({
        message: translate('errors.minimumNumberValueRequired'),
        test: (value, context) => !context.parent.isWorkEstimationCustom || Boolean(value),
      }),
    });

    const initialValues = {
      name: baseService ? baseService.name : '',
      included: baseService ? baseService.included : '',
      notIncluded: baseService ? baseService.notIncluded : '',
      quantity: serviceOrdered ? serviceOrdered.quantity : 1,
      priceClientValue: baseService && baseService.price && baseService.price.initialValue ? baseService.price.initialValue : 0,
      productPrice: baseService ? baseService.productPrice : 0,
      clientPrice: baseService && baseService.price ? baseService.price.clientPrice : null,
      isWorkEstimationCustom: baseService ? Boolean(baseService.workEstimation) : false,
      workEstimateMin: baseService && baseService.workEstimation !== undefined ? baseService.workEstimation.durationMinutes : 0,
      service: baseService,
      customServiceTitle: '',
    };

    return [validationSchema, initialValues];
  }, [orderSession]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      let priceForProductPricePercent = 0;
      if (values.clientPrice && values.clientPrice.value && values.clientPrice.type === PRICE_VALUE_TYPE.percent) {
        priceForProductPricePercent = values.clientPrice.value * values.productPrice * 0.01 * values.quantity;
      }
      try {
        setSubmitting(true);
        const dto: CreateServiceOrderedRequestDTO = {
          name: values.name,
          custom: false,
          paid: serviceOrdered ? serviceOrdered.isPaid : false,
          quantity: values.quantity,
          includedText: values.included,
          notIncludedText: values.notIncluded,
          technicalName: baseService ? baseService.technicalName : StringUtils.generateUUIDv4(),
          price: {
            currency,
            discount: baseService ? baseService.price.discount : { type: 'PERCENT', value: 0 },
            value:
              values.clientPrice && values.clientPrice.type !== PRICE_VALUE_TYPE.percent
                ? values.priceClientValue
                : priceForProductPricePercent,
            catalogPrice: values.clientPrice,
          },
          productPrice: values.clientPrice?.type !== PRICE_VALUE_TYPE.percent ? null : values.productPrice,
          paymentType: serviceOrdered ? serviceOrdered.paymentType : undefined,
          catalogId: baseService ? baseService.catalogId : undefined,
          orderId: orderSession?.id || '',
          workEstimation: values.isWorkEstimationCustom ? { durationMinutes: values.workEstimateMin } : undefined,
          linkedEntities: [],
          promotion: baseService ? baseService.promotion : undefined,
        };
        setIsCreated(true);
        return submitHandlerNoNavigate(dto);
      } catch (error) {
        handleFormErrors({
          callback: () => {
            setSubmitting(false);
            setIsCreated(false);
          },
          error,
        });
      }
    },
  });

  const currencySymbol = services.language.getCurrencySymbol(currency);
  const { values, isSubmitting } = formState;
  const { priceClientValue, quantity, isWorkEstimationCustom, clientPrice, productPrice } = values;

  const { discountsValues, totalClientValue, discountsBlock } = useMemo(() => {
    const totalClientValue = baseService.promotion?.priceAfter;
    const discountsValues = ServiceOrderedUtils.getDiscountsValue(
      baseService.promotion?.discounts || [],
      statefulUtils.money,
      services.language
    );
    const discountsBlock = ServiceOrderedUtils.getDiscounts(baseService.promotion?.discounts || [], statefulUtils.money, services.language);

    return {
      discountsValues,
      totalClientValue,
      discountsBlock,
    };
  }, [priceClientValue, baseService?.id, quantity]);

  const isSubmittingForm = isSubmitting || !isCreated;
  const isJustCreated = !isSubmitting && isCreated;
  const isServiceInOrder = serviceOrdered || isCreated;

  return (
    <FormProvider
      formState={formState}
      fullWidth
      containerSx={{
        flex: 1,
        outline: isOpened ? (theme) => `1px solid ${theme.palette.grey[300]}` : {},
        borderRadius: 1,
        p: 1,
        pl: 2,
        ...sx,
      }}
    >
      <Box sx={{ mt: 3, ...sx }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column" spacing={1}>
            {!!price.service.package && (
              <Box>
                <Chip
                  label={price.service.package.name}
                  size="small"
                  sx={(theme) => ({
                    background: theme.palette.grey[200],
                    color: theme.palette.grey[600],
                  })}
                />
              </Box>
            )}

            <Typography variant="subtitle2" color="text.main">
              {price.name}
            </Typography>

            <ServiceOrderedValueWithDiscount
              priceClientInitialValue={baseService.promotion?.priceBefore || priceClientValue}
              discountsValues={discountsValues}
              totalClientValue={totalClientValue || 0}
              catalogPrice={clientPrice}
              productPrice={productPrice}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            {isServiceInOrder && !isMobile && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  // @prettier-ignore
                  sx={{ backgroundColor: '#EBF8F1', px: 1, py: 0.5, borderRadius: 5, whiteSpace: 'nowrap' }}
                >
                  <Typography variant="body2" color="primary">
                    {translate('entities.service.serviceAdded')}
                  </Typography>
                </Box>
              </Box>
            )}

            <SpoilerPlusIconButton
              isOpened={isOpened}
              onClick={() => {
                if (isServiceInOrder) {
                  duplicateAndOpenBlockId(duplicated ? null : price.id);
                  setDuplicated(true);
                } else {
                  setOpenedBlockId(isOpened ? null : price.id);
                }
              }}
            />
          </Stack>
        </Stack>

        {isOpened && (
          <Box sx={{ mt: 3 }}>
            <Divider sx={{ mr: 1 }} />
            <Box sx={{ maxWidth: '450px', width: { xs: '100%', md: '450px' } }}>
              {Boolean(discountsBlock.length) && <ServiceOrderedDiscountInfoBlock discountsBlock={discountsBlock} labelColor="default" />}

              <Box sx={{ mb: 1, mt: 3, display: 'grid', gap: 3, gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' } }}>
                {clientPrice && clientPrice.type === PRICE_VALUE_TYPE.percent && (
                  <TextFieldMemorized
                    type="number"
                    label={`${translate('entities.service.productPrice')}, ${currencySymbol}`}
                    fieldName="productPrice"
                    formState={formState}
                    fullWidth
                  />
                )}
                <AmountInputMemorized
                  label={translate('fields.quantity')}
                  fieldName="quantity"
                  formState={formState}
                  fullWidth
                  disabled={isCreated}
                />
              </Box>
              <Stack direction="column" spacing={1}>
                <PriceWithTooltip
                  price={clientPrice}
                  text={translate('entities.price.forClient')}
                  quantity={quantity}
                  productPrice={productPrice}
                  initialPrice={totalClientValue}
                  isClientPrice={true}
                />
              </Stack>
              {baseService && (
                <Spoiler sx={{ mt: 3.5 }} title={translate('entities.service.jobDetails')} variant="subtitle1" color="text.secondary">
                  <ServiceOrderedDetailsBlock service={baseService} defaultChildOpenState={true} />
                </Spoiler>
              )}

              <Box sx={{ mt: 2 }}>
                <CheckboxMemorized
                  variant="switch"
                  formState={formState}
                  fieldName="isWorkEstimationCustom"
                  label={translate('pages.serviceEdit.setEstimateWorkTime')}
                  disabled={isCreated}
                />
                <Box sx={{ mt: 3 }}>
                  {isWorkEstimationCustom && !isCreated && <TimeInputMemorized formState={formState} fieldName="workEstimateMin" />}
                </Box>
              </Box>

              {isSubmittingForm && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  fullWidth={isMobile}
                  sx={{ flexGrow: { xs: 1, md: 'inherit' }, mt: 2.5, mb: 5 }}
                >
                  {translate('entities.service.serviceAdd')}
                </LoadingButton>
              )}

              {isJustCreated && (
                <OrderSessionServiceItemEditButtonGroup
                  canDelete={canDeleteCatalogService && Boolean(serviceOrdered) && isCreated}
                  serviceOrderedId={serviceOrdered?.id}
                  setNotCreatedHandler={setNotCreatedHandler}
                  deleteHandler={deleteHandler}
                  closeAddBlock={closeAddBlock}
                  {...rest}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
}

export default React.memo(
  OrderSessionServiceItemEditBlock,
  (prevProps, nextProps) =>
    prevProps.isOpened === nextProps.isOpened &&
    ModelUtils.checkEquality(prevProps.orderSession, nextProps.orderSession) &&
    prevProps.totalQuantity === nextProps.totalQuantity
);
