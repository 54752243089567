import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import useLocales from 'hooks/useLocales';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'storage';
import { PATH_PARAM_DASHBOARD } from 'services/routing/PATH_PARAM';
import useCurrentTabs from './useSuperAdminTabs';
import useInitializePage from 'hooks/useInitializePage';
import InfoList, { InfoListRowProps } from 'components/ui/list/InfoList';
import useDI from 'hooks/useDI';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';
import CreateSuperadminApiKeyButton from 'components/_dashboardPagesFeatures/superadmin/settings/apikey/CreateSuperadminApiKeyButton';
import ResetSuperAdminApiKeyButton from 'components/_dashboardPagesFeatures/superadmin/settings/apikey/ResetSuperAdminApiKeyButton';

function SuperAdminApiSettingsInfoPage() {
  const { storageActions } = useDI();
  const { permissions, currentUser } = useSelector((state) => state.auth);
  const { translate } = useLocales();
  const tabs = useCurrentTabs();
  const handleFormErrors = useSnackbarErrorHandler();
  const [isApiUsersLoading, setIsApiUsersLoading] = useState(true);
  const [apiUserList, setApiUserList] = useState<EntityWithTenant[]>([]);
  const [isNewKeyPopupOpened, setIsNewKeyPopupOpened] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const userSuperadminActions = storageActions.requestScope.userSuperadmin();
    userSuperadminActions
      .getApiUserList({ tenantId: currentUser?.tenant.id || '' })
      .then((apiUserList) => setApiUserList(apiUserList))
      .catch((error) => handleFormErrors({ error, source: 'getApiUserList' }))
      .finally(() => setIsApiUsersLoading(false));
    return () => {
      userSuperadminActions.cancelRequests();
    };
  }, []);
  const isPageInitialized = useInitializePage(() => {
    const menu: MenuIconItemData[] = [];
    if (permissions.can(FIELD_PERMISSION.CAN_READ_SUPER_ADMIN_API_SETTINGS)) {
      menu.push({
        title: translate('pages.enterpriseApiSettingsEdit.title'),
        handler: () => setIsNewKeyPopupOpened(true),
        icon: 'add',
      });
    }
    const currentTab = PATH_PARAM_DASHBOARD.enterprise.apiSettings;
    const title = translate('entities.enterprise.apiKey');
    return { menu, title, tabs, currentTab };
  }, [isApiUsersLoading]);

  if (!isPageInitialized) return <LoaderComponent />;
  if (!currentUser) return null;

  const listData: InfoListRowProps[] = [];
  apiUserList.forEach((apiUser) => {
    listData.push({
      id: 'apiKey',
      title: `${translate('entities.enterprise.apiKey')}: ${apiUser.technicalName}`,
      content: <ResetSuperAdminApiKeyButton apiUserId={apiUser.id} />,
    });
  });

  return (
    <>
      <CreateSuperadminApiKeyButton closePopup={() => setIsNewKeyPopupOpened(false)} isResetPopupOpened={isNewKeyPopupOpened} />
      <InfoList data={listData} />
    </>
  );
}

export default React.memo(SuperAdminApiSettingsInfoPage, () => true);
