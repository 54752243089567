import { LegalEntityResponseDTO } from 'typings/dto/legalEntity';
import Mapper from './Mapper';

export default class LegalEntityMapper extends Mapper<LegalEntity, LegalEntityResponseDTO> {
  constructor() {
    super({
      name: 'name',
      taxId: 'taxId',
      technicalName: 'technicalName',
      type: 'type',
      typeSpecificFields: 'typeSpecificFields',
      legalAddress: 'legalAddress',
      mailingAddress: 'mailingAddress',
      checkingAccount: 'checkingAccount',
      bankName: 'bankName',
      bik: 'bik',
      correspondentAccount: 'correspondentAccount',
      bankAddress: 'bankAddress',
      contactPhone: 'contactPhone',
      contactEmail: 'contactEmail',
      website: 'website',
      code1c: 'code1c',
    });
  }

  public responseDTOToModel(dto: LegalEntityResponseDTO): LegalEntity {
    const legalAddress = {
      state: dto.legalAddress?.state || '',
      city: dto.legalAddress?.city || '',
      address: dto.legalAddress?.address || '',
      zip: dto.legalAddress?.zip || '',
    };
    const mailingAddress = {
      state: dto.mailingAddress?.state || '',
      city: dto.mailingAddress?.city || '',
      address: dto.mailingAddress?.address || '',
      zip: dto.mailingAddress?.zip || '',
    };
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      legalAddress,
      mailingAddress,
    };
  }
}
