import MODEL_NAME from 'typings/models/_model.enum';
import defaultState from 'storage/defaultState';
import SliceFactory from './SliceFactory';

const initialState: UserNotificationState = {
  ...defaultState,

  notification: null,
  notificationAll: [],
  notificationAllAreLoading: false,
  notificationIsLoading: false,
  notificationList: [],

  unreadNotificationList: [],
  readNotificationList: [],
  isUrgentList: false,
  isPopupOpened: false,
  unreadNotificationTotal: 0,
};

const reducers = SliceFactory.createModelReducers<MODEL_NAME.notification, UserNotification, UserNotificationState>(
  MODEL_NAME.notification,
  initialState
);

reducers.setList = function (state, action) {
  const { data, pagination, requestProps } = action.payload;
  state.isListOutdated = false;
  state.error = null;
  state.pagination = pagination;
  state.lastListRequestFilter = requestProps?.lastListRequestFilter;

  state.listOfEntities = data;
  state.notificationList = data;
  // Отличие от дефолтного метода здесь
  const indexFirstRead = data.findIndex((n) => !n.isUnread);
  state.unreadNotificationList = indexFirstRead !== -1 ? data.slice(0, indexFirstRead) : data;
  state.readNotificationList = indexFirstRead !== -1 ? data.slice(indexFirstRead) : [];

  if (!state.isUrgentList) {
    state.unreadNotificationTotal = state.unreadNotificationList.length;
  }
};

reducers.setIsUrgentList = function (state, action) {
  state.isUrgentList = action.payload;
};

reducers.setPopupOpened = function (state, action) {
  state.isPopupOpened = action.payload;
};

reducers.setUnreadNotificationTotal = function (state, action) {
  state.unreadNotificationTotal = action.payload;
};

const notificationSlice = SliceFactory.createModelSlice<MODEL_NAME.notification, UserNotification, UserNotificationState>(
  MODEL_NAME.notification,
  initialState,
  reducers
);

export default notificationSlice;
