import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import { Box, Button, Link, Stack, Tab, Tabs } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RoutingService from 'services/routing/RoutingService';
import FormProvider from 'components/ui/forms/FormProvider';
import { useFormik } from 'formik';
import TogglePasswordButton from 'components/ui/buttons/TogglePasswordButton';
import { EMAIL_VALIDATION_REGEXP, PASSWORD_MIN_LENGTH } from 'configs/vars';
import useDI from 'hooks/useDI';
import PhoneInputMemorized from 'components/ui/forms/PhoneInputMemorized';
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';
import { EmailIcon, PhoneIcon } from 'components/ui/icons';
import DividerWithText from 'components/ui/info/OrDivider';
import { FRONTEND_ROOT, KEYCLOAK_REALM, KEYCLOAK_ROOT } from 'configs/apis';

export default function LoginForm() {
  const { statefulUtils, storageActions, services } = useDI();
  const { translate } = services.language;
  const [showPassword, setShowPassword] = useState(false);
  const [loginMethod, setLoginMethod] = useState<'email' | 'phone'>('email');
  const formErrorHandler = useSnackbarErrorHandler();

  const initialValues = {
    email: '',
    phone: '',
    password: '',
  };

  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const emailIsNotValidText = translate('errors.emailIsNotValid');
  const phoneOrEmailRequiredText = translate('errors.phoneOrEmailRequired');
  const invalidPhoneFormatText = translate('errors.invalidPhoneFormat');
  const valueIsTooSmallText = translate('errors.minimumStrLengthValueRequired');
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_VALIDATION_REGEXP, emailIsNotValidText)
      .test('oneOfFields', phoneOrEmailRequiredText, (thisValue, testContext) => Boolean(testContext.parent.phone || thisValue)),
    phone: Yup.string()
      .test({ message: invalidPhoneFormatText, test: (v) => !v || statefulUtils.phone.validate(v) })
      .test({ message: phoneOrEmailRequiredText, test: (thisValue, testContext) => Boolean(testContext.parent.email || thisValue) }),
    password: Yup.string().min(PASSWORD_MIN_LENGTH, valueIsTooSmallText).required(fieldIsRequiredText),
  });

  const formikState = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting, setFieldValue }) => {
      const dto = { password: values.password, emailOrPhone: values.email || statefulUtils.phone.getRFC3966(values.phone) };
      storageActions.auth.login(dto).catch((error) => {
        formErrorHandler({ setSubmitting, error });
        setFieldValue('password', '');
      });
    },
  });

  const { isSubmitting, setFieldValue } = formikState;
  useEffect(() => {
    if (loginMethod === 'email') setFieldValue('phone', '');
    if (loginMethod === 'phone') setFieldValue('email', '');
  }, [loginMethod]);

  const handleLoginViaAdfs = useCallback(() => {
    window.location.replace(
      `${KEYCLOAK_ROOT}/${KEYCLOAK_REALM}/protocol/openid-connect/auth?client_id=marya-clicker-stage&redirect_uri=${FRONTEND_ROOT}/auth/login/code&response_type=code&scope=openid email&kc_idp_hint=oidc`
    );
  }, []);

  return (
    <FormProvider formState={formikState} sx={{ gap: 2 }}>
      <Tabs
        value={loginMethod}
        onChange={(_, value) => setLoginMethod(value)}
        TabIndicatorProps={{
          sx: {
            display: { md: 'block', xs: 'none' },
          },
        }}
        sx={(theme) => ({
          mb: 1,
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 400,
            color: theme.palette.text.secondary,
          },
          '& .MuiTab-root .MuiSvgIcon-root': {
            display: { xs: 'none', md: 'block' },
          },
          '& .MuiTab-root.Mui-selected .MuiSvgIcon-root': {
            fontWeight: 400,
            color: theme.palette.text.secondary,
          },
          '& .MuiTab-root.Mui-selected': {
            color: theme.palette.text.primary,
          },
          '& .MuiTabs-flexContainer': {
            justifyContent: { xs: 'center', md: 'start' },
          },
        })}
      >
        <Tab value={'email'} label={translate('pages.login.enterEmail')} icon={<EmailIcon fontSize="small" />} disableRipple />
        <Tab value={'phone'} label={translate('pages.login.enterPhone')} icon={<PhoneIcon fontSize="small" />} disableRipple />
      </Tabs>

      {loginMethod === 'email' ? (
        <TextFieldMemorized
          fieldName="email"
          placeholder="your@email.com"
          label={translate('pages.auth.email')}
          formState={formikState}
          fullWidth
        />
      ) : (
        <PhoneInputMemorized
          fieldName="phone"
          label={translate('pages.auth.phone')}
          formState={formikState}
          autoFocus
          className={INPUT_FULL_WIDTH_CLASS_NAME}
        />
      )}

      <TextFieldMemorized
        key={`pass_${showPassword}`}
        autoComplete="current-password"
        fieldName="password"
        type={showPassword ? 'text' : 'password'}
        label={translate('pages.auth.password')}
        formState={formikState}
        InputProps={{ endAdornment: <TogglePasswordButton showPassword={showPassword} setShowPassword={setShowPassword} /> }}
        fullWidth
        sx={{ mt: 1 }}
      />

      <Box>
        <Link
          sx={{ mb: { md: 0, xs: 1 }, textAlign: { xs: 'center', md: 'left' } }}
          component={RouterLink}
          variant="subtitle2"
          to={RoutingService.auth.getResetPasswordPath()}
        >
          {translate('pages.login.forgotPassword')}
        </Link>
      </Box>
      <Stack spacing={2}>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {translate('pages.login.enter')}
        </LoadingButton>
        <DividerWithText text={translate('common.or')} />
        <Button fullWidth size="large" variant="outlined" onClick={handleLoginViaAdfs} color="error">
          {translate('pages.login.viaEmail')} @marya.ru
        </Button>
      </Stack>
    </FormProvider>
  );
}
