import { DistributionCity, DistributionCityDTO, DistributionCityResponseDTO } from 'typings/dto/distributionCity';
import Mapper from './Mapper';

export default class DistributionCityMapper extends Mapper<DistributionCity, DistributionCityDTO> {
  constructor() {
    super({
      technicalName: 'technicalName',
      name: 'name',
    });
  }

  public responseDTOToModel = (distributionCity: DistributionCityResponseDTO): DistributionCity => {
    return {
      ...this.responseDTOToModelViaDiffMap(distributionCity),
    };
  };

  // Этот маппер по умолчанию используется при запросе данных для фильтра, а там нужен descriptionsInDropdown
  public responseDTOToModelIdAndNamesOnly = (distributionCity: DistributionCityResponseDTO): DistributionCity => {
    return this.responseDTOToModel(distributionCity);
  };
}
