import { Box, Fade, Paper, Typography } from '@mui/material';
import useLocales from 'hooks/useLocales';
import LoginForm from 'components/_authenticationPagesFeatures/LoginForm';
import { Helmet } from 'react-helmet-async';
import UnauthorizedLayout from 'components/layouts/UnauthorizedLayout';
import ReleaseNotesBlock from 'components/_authenticationPagesFeatures/ReleaseNotesBlock';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { translate } = useLocales();

  return (
    <UnauthorizedLayout>
      <Helmet>
        <title>
          {translate('pages.login.title')} | {translate('common.app.name')}
        </title>
      </Helmet>
      <Paper
        sx={{
          width: { md: 1167, xs: '100%' },
          height: { md: '728px', xs: 'auto' },
          maxHeight: '100hvh',
          borderRadius: 3,
          display: { md: 'grid', xs: 'block' },
          gridTemplateColumns: { md: '3fr 2fr', xs: '1fr' },
          overflow: 'hidden',
          margin: { xs: '40px 16px', md: 'auto' },
        }}
      >
        <Fade in={true}>
          <Box sx={{ padding: { md: '72px 80px 140px', xs: '20px 24px 32px' } }}>
            <Box sx={{ maxWidth: { md: 480, xs: '100%' } }}>
              <Box sx={{ flexGrow: 1, mb: 2.5, textAlign: { xs: 'center', md: 'left' } }}>
                <Typography variant="h4" gutterBottom>
                  {translate('pages.login.title')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{translate('pages.login.subtitle')}</Typography>
              </Box>
              <LoginForm />
            </Box>
          </Box>
        </Fade>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.neutral,
            pt: { xs: 3, md: 9 },
            px: { xs: 3, md: 4 },
          })}
        >
          <ReleaseNotesBlock />
        </Box>
      </Paper>
    </UnauthorizedLayout>
  );
}
