import DIContainer from 'services/DIContainer';
import {
  CreateUserEnterpriseRequestDTO,
  UserEnterpriseManagerAttributesRequestDTO,
  UserEnterpriseManagerAttributesResponseDTO,
  UserEnterpriseResponseDTO,
  UserEnterpriseTechnicianAttributesRequestDTO,
  UserEnterpriseTechnicianAttributesResponseDTO,
} from 'typings/dto/userEnterprise';
import { USER_ROLE } from 'typings/models/user.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import Mapper from './Mapper';
import UserBaseMapper, { userDTOToModelDiff } from './UserBase';

export default class UserEnterpriseMapper extends Mapper<UserEnterprise, UserEnterpriseResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private defaultUserMapper: UserBaseMapper) {
    super({ ...userDTOToModelDiff, departments: null, enterprise: null });
  }

  public responseDTOToModel = (dto: UserEnterpriseResponseDTO): UserEnterprise => {
    const { position: positionMapper } = this.subEntitiesMappers;
    const tenantUser: UserEnterprise = {
      ...this.defaultUserMapper.responseDTOToModel(dto),
      // todo: костыль
      position: dto.position ? positionMapper.responseDTOToModel(dto.position) : (null as any),
      departments: dto.departments,
      enterprise: dto.enterprise,
      type: dto.tenant?.type as TENANT_TYPE.enterprise,
      tenant: dto.tenant as Tenant<TENANT_TYPE.enterprise>,
      attributes: {} as any,
      isManager: false as any,
      isTechnician: false as any,
    };

    dto.businessRoles?.forEach((role) => {
      switch (role) {
        case USER_ROLE.orderTechnician: {
          tenantUser.isTechnician = true;
          tenantUser.attributes = {
            ...tenantUser.attributes,
            ...this.responseTechnicianAttributesToModel(dto.attributes as UserEnterpriseTechnicianAttributesResponseDTO),
          };
          break;
        }
        case USER_ROLE.orderManager: {
          tenantUser.isManager = true;
          tenantUser.attributes = {
            ...tenantUser.attributes,
            ...this.responseManagerAttributesToModel(dto.attributes as UserEnterpriseManagerAttributesResponseDTO),
          };
          break;
        }
      }
    });

    return tenantUser;
  };

  public responseTechnicianAttributesToModel = (
    dto: UserEnterpriseTechnicianAttributesResponseDTO
  ): UserEnterprise.InternalTechnician['attributes'] => {
    const reviewMapper = this.getOuterMappers();
    return {
      regionServiceCategories: dto.serviceCategories.map((item) => ({ region: item.region, categories: item.serviceCategories })),
      address: dto.address && this.subEntitiesMappers.address.responseDTOToModel(dto.address),
      legalEntity: dto.legalEntity && this.getOuterMappers().legalEntity.responseDTOToModel(dto.legalEntity),
      statistics: {
        ...dto.statistics,
        // Возможно где-то будет не приходить статистика, т.к. она нагружает запрос и далеко не всегда нужна
        reviews: dto.statistics ? dto.statistics.reviews.map(reviewMapper.orderReview.responseDTOToModel) : [],
      },
      // Возможно где-то будет не приходить статистика, т.к. она нагружает запрос и далеко не всегда нужна
      teams: dto.technicianTeams || [],
    };
  };

  public responseManagerAttributesToModel = (dto: UserEnterpriseManagerAttributesResponseDTO): UserEnterprise.Manager['attributes'] => {
    return {
      code: dto.code || 'test',
    };
  };

  // Метод переиспользуется в маппере userPlatformOperator, но у них разный TENANT.TYPE, поэтому user типизирован юнион тайпом.
  public modelToRequestDTO(user: UserEnterprise | UserPlatformOperator): CreateUserEnterpriseRequestDTO {
    const dtoUser: CreateUserEnterpriseRequestDTO = {
      ...this.defaultUserMapper.modelToRequestDTO(user),
      tenantId: user.tenant.id,
      attributes: {} as any,
    };

    user.roles.forEach((role) => {
      switch (role) {
        case USER_ROLE.orderTechnician: {
          dtoUser.attributes = {
            ...dtoUser.attributes,
            ...this.modelTechnicianAttributesToRequestDTO(user.attributes as UserEnterprise.InternalTechnician['attributes']),
          };
          break;
        }
        case USER_ROLE.orderManager: {
          dtoUser.attributes = {
            ...dtoUser.attributes,
            ...this.modelManagerAttributesToRequestDTO(user.attributes as UserEnterprise.Manager['attributes']),
          };
          break;
        }
      }
    });

    return dtoUser;
  }

  public modelTechnicianAttributesToRequestDTO(
    user: UserEnterprise.InternalTechnician['attributes']
  ): UserEnterpriseTechnicianAttributesRequestDTO {
    return {
      serviceCategories: user.regionServiceCategories.map((item) => ({
        regionId: item.region.id,
        serviceCategories: item.categories.map((c) => c.id),
      })),
      legalEntityId: user.legalEntity?.id,
      address: user.address && this.subEntitiesMappers.address.modelToRequestDTO(user.address),
    };
  }

  public modelManagerAttributesToRequestDTO(user: UserEnterprise.Manager['attributes']): UserEnterpriseManagerAttributesRequestDTO {
    return {
      code: user.code,
    };
  }

  public responseDTOToModelIdAndNamesOnly = (dto: UserEnterpriseResponseDTO): UserEnterprise => {
    const user = this.responseDTOToModelViaDiffMap(dto);
    if (!user.name) {
      // В некоторых эндпоинтах для фильтров юзеры приходят как EntityWithName и стандартный маппер выше не найдёт имя
      user.name = (dto as unknown as EntityWithName).name;
    }
    return user;
  };
}
