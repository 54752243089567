import React, { useMemo, useState } from 'react';
import { Box, Stack, SxProps, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Theme } from '@mui/material/styles';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import useLocales from 'hooks/useLocales';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import FormProvider from 'components/ui/forms/FormProvider';
import ServiceOrderedDetailsBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedDetailsBlock';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import { EDIT_FORM_WIDTH, INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';
import Spoiler from 'components/ui/info/Spoiler';
import AmountInputMemorized from 'components/ui/forms/AmountInputMemorized';
import ILanguageService from 'services/language/ILanguageService';
import useDI from 'hooks/useDI';
import StringUtils from 'utils/String';
import TimeInputMemorized from 'components/ui/forms/dateTime/TimeInputMemorized';
import CheckboxMemorized from 'components/ui/forms/CheckboxMemorized';
import NumberUtils from 'utils/Number';
import { PRICE_VALUE_TYPE } from '../../../../../../typings/models/price.enum';

type BaseProps = {
  orderSession: OrderContractSessionData;
  serviceOrdered: ServiceOrdered;
  submitHandler: (dto: CreateServiceOrderedRequestDTO) => any | Promise<any>;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  currency: ILanguageService.CurrencyCode;
  closeHandler: VoidFunction;
};

function ServiceOrderedSessionEditBlock({ serviceOrdered, submitHandler, fullWidth, sx, orderSession, currency, closeHandler }: BaseProps) {
  const { translate } = useLocales();
  const handleFormErrors = useSnackbarErrorHandler();
  const { services, statefulUtils } = useDI();

  const [baseService] = useState<ServiceOrdered | null>(serviceOrdered);

  const [isCustom] = useState(serviceOrdered.isCustom);

  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const valueIsTooLowText = translate('errors.valueIsTooSmall');
  const [validationSchema, initialValues] = useMemo(() => {
    const validationSchema = Yup.object().shape({
      service: Yup.mixed().test({ message: fieldIsRequiredText, test: (v) => isCustom || Boolean(v) }),
      name: Yup.string().trim(fieldIsRequiredText).required(fieldIsRequiredText),
      description: Yup.string(),
      included: Yup.string(),
      notIncluded: Yup.string(),
      quantity: Yup.number().min(1, valueIsTooLowText).required(fieldIsRequiredText),
      priceClientValue: Yup.number()
        .min(0, valueIsTooLowText)
        .test({
          message: translate('entities.pricelist.currencyDecimalNumberExceededValidationError'),
          test: (v) => NumberUtils.getDecimalCount(v || 0) <= 2,
        })
        .required(fieldIsRequiredText),
      isWorkEstimationCustom: Yup.boolean(),
      workEstimateMin: Yup.number().test({
        message: translate('errors.minimumNumberValueRequired'),
        test: (value, context) => !context.parent.isWorkEstimationCustom || Boolean(value),
      }),
    });

    const initialValues = {
      name: baseService ? baseService.name : '',
      included: baseService ? baseService.included : '',
      notIncluded: baseService ? baseService.notIncluded : '',
      quantity: serviceOrdered.quantity,
      priceClientValue: baseService && baseService.price ? baseService.price.initialValue : 0,
      productPrice: baseService ? baseService.productPrice : 0,
      clientPrice: baseService && baseService.price ? baseService.price.clientPrice : null,
      isWorkEstimationCustom: baseService ? Boolean(baseService.workEstimation) : false,
      workEstimateMin: baseService && baseService.workEstimation !== undefined ? baseService.workEstimation.durationMinutes : 0,

      isCustom, // Чтобы enableReinitialize отслеживал изменения
      service: baseService, // Чтобы enableReinitialize отслеживал изменения
      customServiceTitle: '', // Для отображения заголовка формы при добавлении кастомной услуги на странице создания заказа,
    };

    return [validationSchema, initialValues];
  }, [baseService?.id, isCustom]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      let priceForProductPricePercent = 0;
      if (values.clientPrice && values.clientPrice.value && values.clientPrice.type === PRICE_VALUE_TYPE.percent) {
        priceForProductPricePercent = values.clientPrice.value * values.productPrice * 0.01 * values.quantity;
      }
      try {
        setSubmitting(true);
        const dto: CreateServiceOrderedRequestDTO = {
          name: values.name,
          custom: values.isCustom,
          paid: serviceOrdered.isPaid,
          quantity: values.quantity,
          includedText: values.included,
          notIncludedText: values.notIncluded,
          technicalName: baseService ? baseService.technicalName : StringUtils.generateUUIDv4(),
          price: {
            currency,
            discount: baseService ? baseService.price.discount : { type: 'PERCENT', value: 0 },
            value:
              values.clientPrice && values.clientPrice.type !== PRICE_VALUE_TYPE.percent
                ? values.priceClientValue
                : priceForProductPricePercent,
            catalogPrice: values.clientPrice,
          },
          productPrice: values.clientPrice?.type !== PRICE_VALUE_TYPE.percent ? null : values.productPrice,
          paymentType: serviceOrdered.paymentType,
          catalogId: baseService ? baseService.catalogId : undefined,
          orderId: orderSession?.id || '',
          workEstimation: values.isWorkEstimationCustom ? { durationMinutes: values.workEstimateMin } : undefined,
          linkedEntities: [],
          promotion: baseService ? baseService.promotion : undefined,
        };
        return submitHandler(dto);
      } catch (error) {
        handleFormErrors({ callback: () => setSubmitting(false), error });
      }
    },
  });

  const currencySymbol = services.language.getCurrencySymbol(currency);
  const { values, isSubmitting } = formState;
  const { priceClientValue, quantity, isWorkEstimationCustom } = values;

  const customServiceBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    formState.setFieldTouched('name', true);
    formState.setFieldValue('customServiceTitle', event.target.value);
  };

  const isServiceSelected = isCustom ? true : Boolean(baseService);

  return (
    <FormProvider
      formState={formState}
      fullWidth={fullWidth}
      containerSx={{
        flex: fullWidth ? 1 : undefined,
        maxWidth: fullWidth ? undefined : EDIT_FORM_WIDTH,
        ...sx,
      }}
    >
      {isCustom ? (
        <TextFieldMemorized
          formState={formState}
          fieldName="name"
          label={translate('fields.name_title')}
          fullWidth
          onBlur={customServiceBlurHandler}
        />
      ) : (
        <TextField label={translate('entities.service.type.native')} value={values.service?.name || ''} disabled />
      )}

      <Stack spacing={2}>
        {isServiceSelected && (
          <Box>
            <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' } }}>
              <TextFieldMemorized
                disabled={!isCustom}
                type="number"
                label={`${translate('entities.price.forClient')}, ${currencySymbol}`}
                fieldName="priceClientValue"
                formState={formState}
                fullWidth
              />
              <AmountInputMemorized label={translate('fields.quantity')} fieldName="quantity" formState={formState} fullWidth />
              <TextField
                variant="outlined"
                label={`${translate('entities.service.clientPriceTotal')}, ${currencySymbol}`}
                value={statefulUtils.money.toString(
                  priceClientValue * quantity,
                  services.language.getCurrentLocale().localeCurrency,
                  false
                )}
                disabled
                className={INPUT_FULL_WIDTH_CLASS_NAME}
              />
            </Box>
          </Box>
        )}

        {isCustom ? (
          <Spoiler
            key="custom_spoiler"
            defaultState={true}
            title={translate('entities.service.jobDetails')}
            variant="subtitle1"
            color="text.secondary"
          >
            <Stack spacing={2}>
              <TextFieldMemorized
                fullWidth
                multiline
                minRows={5}
                label={translate('pages.orderServiceEdit.includedHelpText')}
                fieldName="included"
                formState={formState}
              />
              <TextFieldMemorized
                fullWidth
                multiline
                minRows={5}
                label={translate('pages.orderServiceEdit.notIncludedHelpText')}
                fieldName="notIncluded"
                formState={formState}
              />
            </Stack>
          </Spoiler>
        ) : (
          baseService && (
            <Spoiler title={translate('entities.service.jobDetails')} variant="subtitle1" color="text.secondary">
              <ServiceOrderedDetailsBlock service={baseService} defaultChildOpenState={true} />
            </Spoiler>
          )
        )}

        {isServiceSelected && (
          <>
            <CheckboxMemorized
              variant="switch"
              formState={formState}
              fieldName="isWorkEstimationCustom"
              label={translate('pages.serviceEdit.setEstimateWorkTime')}
            />
            {isWorkEstimationCustom && <TimeInputMemorized formState={formState} fieldName="workEstimateMin" />}
          </>
        )}
      </Stack>

      <ConfirmButtonGroup isLoading={isSubmitting} cancelHandler={closeHandler} />
    </FormProvider>
  );
}

export default React.memo(ServiceOrderedSessionEditBlock, () => true);
