export enum MODEL_PERMISSION {
  // Enterprise
  CAN_READ_ENTERPRISE = 'CAN_READ_ENTERPRISE',
  CAN_READ_ENTERPRISE_LIST = 'CAN_READ_ENTERPRISE_LIST',
  CAN_CREATE_ENTERPRISE = 'CAN_CREATE_ENTERPRISE',
  CAN_UPDATE_ENTERPRISE = 'CAN_UPDATE_ENTERPRISE',
  CAN_DELETE_ENTERPRISE = 'CAN_DELETE_ENTERPRISE',
  // PlatformOperator
  CAN_READ_PLATFORM_OPERATOR = 'CAN_READ_PLATFORM_OPERATOR',
  CAN_READ_PLATFORM_OPERATOR_LIST = 'CAN_READ_PLATFORM_OPERATOR_LIST',
  CAN_CREATE_PLATFORM_OPERATOR = 'CAN_CREATE_PLATFORM_OPERATOR',
  CAN_UPDATE_PLATFORM_OPERATOR = 'CAN_UPDATE_PLATFORM_OPERATOR',
  CAN_DELETE_PLATFORM_OPERATOR = 'CAN_DELETE_PLATFORM_OPERATOR',
  // Enterprise region
  CAN_READ_ENTERPRISE_REGION = 'CAN_READ_ENTERPRISE_REGION',
  CAN_READ_ENTERPRISE_REGION_LIST = 'CAN_READ_ENTERPRISE_REGION_LIST',
  CAN_CREATE_ENTERPRISE_REGION = 'CAN_CREATE_ENTERPRISE_REGION',
  CAN_UPDATE_ENTERPRISE_REGION = 'CAN_UPDATE_ENTERPRISE_REGION',
  CAN_DELETE_ENTERPRISE_REGION = 'CAN_DELETE_ENTERPRISE_REGION',
  // Brand
  CAN_READ_BRAND = 'CAN_READ_BRAND',
  CAN_READ_BRAND_LIST = 'CAN_READ_BRAND_LIST',
  CAN_CREATE_BRAND = 'CAN_CREATE_BRAND',
  CAN_UPDATE_BRAND = 'CAN_UPDATE_BRAND',
  CAN_DELETE_BRAND = 'CAN_DELETE_BRAND',
  // Enterprise department
  CAN_READ_ENTERPRISE_DEPARTMENT = 'CAN_READ_ENTERPRISE_DEPARTMENT',
  CAN_READ_ENTERPRISE_DEPARTMENT_LIST = 'CAN_READ_ENTERPRISE_DEPARTMENT_LIST',
  CAN_CREATE_ENTERPRISE_DEPARTMENT = 'CAN_CREATE_ENTERPRISE_DEPARTMENT',
  CAN_UPDATE_ENTERPRISE_DEPARTMENT = 'CAN_UPDATE_ENTERPRISE_DEPARTMENT',
  CAN_DELETE_ENTERPRISE_DEPARTMENT = 'CAN_DELETE_ENTERPRISE_DEPARTMENT',
  // Enterprise legal entity
  CAN_READ_ENTERPRISE_LEGAL_ENTITY = 'CAN_READ_ENTERPRISE_LEGAL_ENTITY',
  CAN_READ_ENTERPRISE_LEGAL_ENTITY_LIST = 'CAN_READ_ENTERPRISE_LEGAL_ENTITY_LIST',
  CAN_CREATE_ENTERPRISE_LEGAL_ENTITY = 'CAN_CREATE_ENTERPRISE_LEGAL_ENTITY',
  CAN_UPDATE_ENTERPRISE_LEGAL_ENTITY = 'CAN_UPDATE_ENTERPRISE_LEGAL_ENTITY',
  CAN_DELETE_ENTERPRISE_LEGAL_ENTITY = 'CAN_DELETE_ENTERPRISE_LEGAL_ENTITY',
  // Enterprise service category group
  CAN_READ_ENTERPRISE_SERVICE_GROUP = 'CAN_READ_ENTERPRISE_SERVICE_GROUP',
  CAN_READ_ENTERPRISE_SERVICE_GROUP_LIST = 'CAN_READ_ENTERPRISE_SERVICE_GROUP_LIST',
  CAN_CREATE_ENTERPRISE_SERVICE_GROUP = 'CAN_CREATE_ENTERPRISE_SERVICE_GROUP',
  CAN_UPDATE_ENTERPRISE_SERVICE_GROUP = 'CAN_UPDATE_ENTERPRISE_SERVICE_GROUP',
  CAN_DELETE_ENTERPRISE_SERVICE_GROUP = 'CAN_DELETE_ENTERPRISE_SERVICE_GROUP',
  // Enterprise Service category
  CAN_READ_ENTERPRISE_SERVICE_CATEGORY = 'CAN_READ_ENTERPRISE_SERVICE_CATEGORY',
  CAN_READ_ENTERPRISE_SERVICE_CATEGORY_LIST = 'CAN_READ_ENTERPRISE_SERVICE_CATEGORY_LIST',
  CAN_CREATE_ENTERPRISE_SERVICE_CATEGORY = 'CAN_CREATE_ENTERPRISE_SERVICE_CATEGORY',
  CAN_UPDATE_ENTERPRISE_SERVICE_CATEGORY = 'CAN_UPDATE_ENTERPRISE_SERVICE_CATEGORY',
  CAN_DELETE_ENTERPRISE_SERVICE_CATEGORY = 'CAN_DELETE_ENTERPRISE_SERVICE_CATEGORY',
  // Enterprise service
  CAN_READ_ENTERPRISE_SERVICE = 'CAN_READ_ENTERPRISE_SERVICE',
  CAN_READ_ENTERPRISE_SERVICE_LIST = 'CAN_READ_ENTERPRISE_SERVICE_LIST',
  CAN_CREATE_ENTERPRISE_SERVICE = 'CAN_CREATE_ENTERPRISE_SERVICE',
  CAN_UPDATE_ENTERPRISE_SERVICE = 'CAN_UPDATE_ENTERPRISE_SERVICE',
  CAN_DELETE_ENTERPRISE_SERVICE = 'CAN_DELETE_ENTERPRISE_SERVICE',
  // Service package
  CAN_READ_SERVICE_PACKAGE = 'CAN_CREATE_SERVICE_PACKAGE',
  CAN_CREATE_SERVICE_PACKAGE = 'CAN_CREATE_SERVICE_PACKAGE',
  CAN_UPDATE_SERVICE_PACKAGE = 'CAN_CREATE_SERVICE_PACKAGE',
  // Enterprise user
  CAN_READ_ENTERPRISE_USER = 'CAN_READ_ENTERPRISE_USER',
  CAN_READ_ENTERPRISE_USER_LIST = 'CAN_READ_ENTERPRISE_USER_LIST',
  CAN_CREATE_ENTERPRISE_USER = 'CAN_CREATE_ENTERPRISE_USER',
  CAN_UPDATE_ENTERPRISE_USER = 'CAN_UPDATE_ENTERPRISE_USER',
  CAN_DELETE_ENTERPRISE_USER = 'CAN_DELETE_ENTERPRISE_USER',
  // Client price
  CAN_READ_CLIENT_PRICE_LIST = 'CAN_READ_CLIENT_PRICE_LIST',
  CAN_READ_CLIENT_PRICE = 'CAN_READ_CLIENT_PRICE',
  CAN_CREATE_CLIENT_PRICE = 'CAN_CREATE_CLIENT_PRICE',
  CAN_UPDATE_CLIENT_PRICE = 'CAN_UPDATE_CLIENT_PRICE',
  CAN_DELETE_CLIENT_PRICE = 'CAN_DELETE_CLIENT_PRICE',
  CAN_UPDATE_CLIENT_PRICELIST = 'CAN_UPDATE_CLIENT_PRICELIST',
  // Technician price
  CAN_READ_TECHNICIAN_PRICE_LIST = 'CAN_READ_TECHNICIAN_PRICE_LIST',
  CAN_READ_TECHNICIAN_PRICE = 'CAN_READ_TECHNICIAN_PRICE',
  CAN_CREATE_TECHNICIAN_PRICE = 'CAN_CREATE_TECHNICIAN_PRICE',
  CAN_UPDATE_TECHNICIAN_PRICE = 'CAN_UPDATE_TECHNICIAN_PRICE',
  CAN_DELETE_TECHNICIAN_PRICE = 'CAN_DELETE_TECHNICIAN_PRICE',
  CAN_UPDATE_TECHNICIAN_PRICELIST = 'CAN_UPDATE_TECHNICIAN_PRICELIST',
  // Service provider price
  CAN_READ_SERVICE_PROVIDER_PRICE_LIST = 'CAN_READ_SERVICE_PROVIDER_PRICE_LIST',
  CAN_READ_SERVICE_PROVIDER_PRICE = 'CAN_READ_SERVICE_PROVIDER_PRICE',
  CAN_CREATE_SERVICE_PROVIDER_PRICE = 'CAN_CREATE_SERVICE_PROVIDER_PRICE',
  CAN_UPDATE_SERVICE_PROVIDER_PRICE = 'CAN_UPDATE_SERVICE_PROVIDER_PRICE',
  CAN_DELETE_SERVICE_PROVIDER_PRICE = 'CAN_DELETE_SERVICE_PROVIDER_PRICE',
  // Service provider pricelist
  CAN_READ_SERVICE_PROVIDER_PRICELIST_LIST = 'CAN_READ_SERVICE_PROVIDER_PRICELIST_LIST',
  CAN_READ_SERVICE_PROVIDER_PRICELIST = 'CAN_READ_SERVICE_PROVIDER_PRICELIST',
  CAN_CREATE_SERVICE_PROVIDER_PRICELIST = 'CAN_CREATE_SERVICE_PROVIDER_PRICELIST',
  CAN_UPDATE_SERVICE_PROVIDER_PRICELIST = 'CAN_UPDATE_SERVICE_PROVIDER_PRICELIST',
  CAN_DELETE_SERVICE_PROVIDER_PRICELIST = 'CAN_DELETE_SERVICE_PROVIDER_PRICELIST',
  // Platform operator price
  CAN_READ_PLATFORM_OPERATOR_PRICE_LIST = 'CAN_READ_PLATFORM_OPERATOR_PRICE_LIST',
  CAN_READ_PLATFORM_OPERATOR_PRICE = 'CAN_READ_PLATFORM_OPERATOR_PRICE',
  CAN_CREATE_PLATFORM_OPERATOR_PRICE = 'CAN_CREATE_PLATFORM_OPERATOR_PRICE',
  CAN_UPDATE_PLATFORM_OPERATOR_PRICE = 'CAN_UPDATE_PLATFORM_OPERATOR_PRICE',
  CAN_DELETE_PLATFORM_OPERATOR_PRICE = 'CAN_DELETE_PLATFORM_OPERATOR_PRICE',
  // Platform operator pricelist
  CAN_READ_PLATFORM_OPERATOR_PRICELIST_LIST = 'CAN_READ_PLATFORM_OPERATOR_PRICELIST_LIST',
  CAN_READ_PLATFORM_OPERATOR_PRICELIST = 'CAN_READ_PLATFORM_OPERATOR_PRICELIST',
  CAN_CREATE_PLATFORM_OPERATOR_PRICELIST = 'CAN_CREATE_PLATFORM_OPERATOR_PRICELIST',
  CAN_UPDATE_PLATFORM_OPERATOR_PRICELIST = 'CAN_UPDATE_PLATFORM_OPERATOR_PRICELIST',
  CAN_DELETE_PLATFORM_OPERATOR_PRICELIST = 'CAN_DELETE_PLATFORM_OPERATOR_PRICELIST',
  // Enterprise technician
  CAN_READ_ENTERPRISE_TECHNICIAN_LIST = 'CAN_READ_ENTERPRISE_TECHNICIAN_LIST',
  CAN_READ_ENTERPRISE_TECHNICIAN = 'CAN_READ_ENTERPRISE_TECHNICIAN',
  CAN_CREATE_ENTERPRISE_TECHNICIAN = 'CAN_CREATE_ENTERPRISE_TECHNICIAN',
  CAN_UPDATE_ENTERPRISE_TECHNICIAN = 'CAN_UPDATE_ENTERPRISE_TECHNICIAN',
  CAN_DELETE_ENTERPRISE_TECHNICIAN = 'CAN_DELETE_ENTERPRISE_TECHNICIAN',
  // Enterprise technician team
  CAN_READ_ENTERPRISE_TECHNICIAN_TEAM_LIST = 'CAN_READ_ENTERPRISE_TECHNICIAN_TEAM_LIST',
  CAN_READ_ENTERPRISE_TECHNICIAN_TEAM = 'CAN_READ_ENTERPRISE_TECHNICIAN_TEAM',
  CAN_CREATE_ENTERPRISE_TECHNICIAN_TEAM = 'CAN_CREATE_ENTERPRISE_TECHNICIAN_TEAM',
  CAN_UPDATE_ENTERPRISE_TECHNICIAN_TEAM = 'CAN_UPDATE_ENTERPRISE_TECHNICIAN_TEAM',
  CAN_DELETE_ENTERPRISE_TECHNICIAN_TEAM = 'CAN_DELETE_ENTERPRISE_TECHNICIAN_TEAM',
  // ContractSP
  CAN_READ_SP_CONTRACT_LIST = 'CAN_READ_SP_CONTRACT_LIST',
  CAN_READ_SP_CONTRACT = 'CAN_READ_SP_CONTRACT',
  CAN_CREATE_SP_CONTRACT = 'CAN_CREATE_SP_CONTRACT',
  CAN_UPDATE_SP_CONTRACT = 'CAN_UPDATE_SP_CONTRACT',
  CAN_DELETE_SP_CONTRACT = 'CAN_DELETE_SP_CONTRACT',
  // ContractPO
  CAN_READ_PO_CONTRACT_LIST = 'CAN_READ_PO_CONTRACT_LIST',
  CAN_READ_PO_CONTRACT = 'CAN_READ_PO_CONTRACT',
  CAN_CREATE_PO_CONTRACT = 'CAN_CREATE_PO_CONTRACT',
  CAN_UPDATE_PO_CONTRACT = 'CAN_UPDATE_PO_CONTRACT',
  CAN_DELETE_PO_CONTRACT = 'CAN_DELETE_PO_CONTRACT',
  CAN_UPDATE_PO_CONTRACT_PRICELIST_SETTINGS = 'CAN_UPDATE_PO_CONTRACT_PRICELIST_SETTINGS',
  // Service Provider
  CAN_READ_SERVICE_PROVIDER = 'CAN_READ_SERVICE_PROVIDER',
  CAN_READ_SERVICE_PROVIDER_LIST = 'CAN_READ_SERVICE_PROVIDER_LIST',
  CAN_CREATE_SERVICE_PROVIDER = 'CAN_CREATE_SERVICE_PROVIDER',
  CAN_UPDATE_SERVICE_PROVIDER = 'CAN_UPDATE_SERVICE_PROVIDER',
  CAN_DELETE_SERVICE_PROVIDER = 'CAN_DELETE_SERVICE_PROVIDER',
  // Service Provider user
  CAN_READ_SP_USER = 'CAN_READ_SP_USER',
  CAN_READ_SP_USER_LIST = 'CAN_READ_SP_USER_LIST',
  CAN_CREATE_SP_USER = 'CAN_CREATE_SP_USER',
  CAN_UPDATE_SP_USER = 'CAN_UPDATE_SP_USER',
  CAN_DELETE_SP_USER = 'CAN_DELETE_SP_USER',
  // Service Provider legal entity
  CAN_READ_SP_LEGAL_ENTITY = 'CAN_READ_SP_LEGAL_ENTITY',
  CAN_READ_SP_LEGAL_ENTITY_LIST = 'CAN_READ_SP_LEGAL_ENTITY_LIST',
  CAN_CREATE_SP_LEGAL_ENTITY = 'CAN_CREATE_SP_LEGAL_ENTITY',
  CAN_UPDATE_SP_LEGAL_ENTITY = 'CAN_UPDATE_SP_LEGAL_ENTITY',
  CAN_DELETE_SP_LEGAL_ENTITY = 'CAN_DELETE_SP_LEGAL_ENTITY',
  // Service Provider department
  CAN_READ_SP_DEPARTMENT = 'CAN_READ_SP_DEPARTMENT',
  CAN_READ_SP_DEPARTMENT_LIST = 'CAN_READ_SP_DEPARTMENT_LIST',
  CAN_CREATE_SP_DEPARTMENT = 'CAN_CREATE_SP_DEPARTMENT',
  CAN_UPDATE_SP_DEPARTMENT = 'CAN_UPDATE_SP_DEPARTMENT',
  CAN_DELETE_SP_DEPARTMENT = 'CAN_DELETE_SP_DEPARTMENT',
  // Funds Transaction
  CAN_READ_TRANSACTION_TYPE_LIST = 'CAN_READ_TRANSACTION_TYPE_LIST',
  CAN_READ_TRANSACTION_TYPE = 'CAN_READ_TRANSACTION_TYPE',
  CAN_CREATE_TRANSACTION_TYPE = 'CAN_CREATE_TRANSACTION_TYPE',
  CAN_UPDATE_TRANSACTION_TYPE = 'CAN_UPDATE_TRANSACTION_TYPE',
  CAN_DELETE_TRANSACTION_TYPE = 'CAN_DELETE_TRANSACTION_TYPE',
  CAN_CREATE_ORDER_PO_TRANSACTION = 'CAN_CREATE_ORDER_PO_TRANSACTION',
  CAN_UPDATE_ORDER_PO_TRANSACTION = 'CAN_UPDATE_ORDER_PO_TRANSACTION',
  CAN_DELETE_ORDER_PO_TRANSACTION = 'CAN_DELETE_ORDER_PO_TRANSACTION',
  // Order
  CAN_CREATE_ORDER = 'CAN_CREATE_ORDER',
  CAN_UPDATE_ORDER = 'CAN_UPDATE_ORDER',
  CAN_DELETE_ORDER = 'CAN_DELETE_ORDER',
  CAN_READ_ORDER = 'CAN_READ_ORDER',
  CAN_READ_ORDER_LIST = 'CAN_READ_ORDER_LIST',
  CAN_OPEN_CLIENT_PAGE = 'CAN_OPEN_CLIENT_PAGE',
  CAN_ARCHIVE_ORDER = 'CAN_ARCHIVE_ORDER',
  // Order comment
  CAN_READ_ORDER_COMMENT = 'CAN_READ_ORDER_COMMENT',
  CAN_READ_ORDER_COMMENT_LIST = 'CAN_READ_ORDER_COMMENT_LIST',
  CAN_UPDATE_ORDER_COMMENT = 'CAN_UPDATE_ORDER_COMMENT',
  CAN_CREATE_ORDER_COMMENT = 'CAN_CREATE_ORDER_COMMENT',
  CAN_DELETE_ORDER_COMMENT = 'CAN_DELETE_ORDER_COMMENT',
  // Order history
  CAN_READ_ORDER_HISTORY = 'CAN_READ_ORDER_HISTORY',
  CAN_READ_ORDER_HISTORY_LIST = 'CAN_READ_ORDER_HISTORY_LIST',
  CAN_UPDATE_ORDER_HISTORY = 'CAN_UPDATE_ORDER_HISTORY',
  CAN_CREATE_ORDER_HISTORY = 'CAN_CREATE_ORDER_HISTORY',
  CAN_DELETE_ORDER_HISTORY = 'CAN_DELETE_ORDER_HISTORY',
  // Order service
  CAN_READ_ORDER_SERVICE = 'CAN_READ_ORDER_SERVICE',
  CAN_READ_ORDER_SERVICE_LIST = 'CAN_READ_ORDER_SERVICE_LIST',
  CAN_UPDATE_ORDER_SERVICE = 'CAN_UPDATE_ORDER_SERVICE',
  CAN_CREATE_ORDER_SERVICE = 'CAN_CREATE_ORDER_SERVICE',
  CAN_DELETE_ORDER_SERVICE = 'CAN_DELETE_ORDER_SERVICE',
  // Order report
  CAN_READ_ORDER_REPORT_LIST = 'CAN_READ_ORDER_REPORT_LIST',
  CAN_READ_ORDER_REPORT = 'CAN_READ_ORDER_REPORT',
  CAN_UPDATE_ORDER_REPORT = 'CAN_UPDATE_ORDER_REPORT',
  CAN_CREATE_ORDER_REPORT = 'CAN_CREATE_ORDER_REPORT',
  CAN_DELETE_ORDER_REPORT = 'CAN_DELETE_ORDER_REPORT',
  // Order task
  CAN_READ_ORDER_TASK_LIST = 'CAN_READ_ORDER_TASK_LIST',
  CAN_READ_ORDER_TASK = 'CAN_READ_ORDER_TASK',
  CAN_UPDATE_ORDER_TASK = 'CAN_UPDATE_ORDER_TASK',
  CAN_CREATE_ORDER_TASK = 'CAN_CREATE_ORDER_TASK',
  CAN_DELETE_ORDER_TASK = 'CAN_DELETE_ORDER_TASK',
  // Order review
  CAN_READ_ORDER_REVIEW = 'CAN_READ_ORDER_REVIEW',
  CAN_READ_ORDER_REVIEW_LIST = 'CAN_READ_ORDER_REVIEW_LIST',
  CAN_UPDATE_ORDER_REVIEW = 'CAN_UPDATE_ORDER_REVIEW',
  CAN_CREATE_ORDER_REVIEW = 'CAN_CREATE_ORDER_REVIEW',
  CAN_DELETE_ORDER_REVIEW = 'CAN_DELETE_ORDER_REVIEW',
  // Order review comment
  CAN_READ_ORDER_REVIEW_COMMENT = 'CAN_READ_ORDER_REVIEW_COMMENT',
  CAN_READ_ORDER_REVIEW_COMMENT_LIST = 'CAN_READ_ORDER_REVIEW_COMMENT_LIST',
  CAN_UPDATE_ORDER_REVIEW_COMMENT = 'CAN_UPDATE_ORDER_REVIEW_COMMENT',
  CAN_CREATE_ORDER_REVIEW_COMMENT = 'CAN_CREATE_ORDER_REVIEW_COMMENT',
  CAN_DELETE_ORDER_REVIEW_COMMENT = 'CAN_DELETE_ORDER_REVIEW_COMMENT',
  // Order document
  CAN_DELETE_ORDER_DOCUMENT = 'CAN_DELETE_ORDER_DOCUMENT',
  CAN_CREATE_ORDER_DOCUMENT = 'CAN_CREATE_ORDER_DOCUMENT',
  CAN_UPDATE_ORDER_DOCUMENT = 'CAN_UPDATE_ORDER_DOCUMENT',
  // Order view
  CAN_READ_ORDER_VIEW_LIST = 'CAN_READ_ORDER_VIEW_LIST',
  CAN_READ_ORDER_VIEW = 'CAN_READ_ORDER_VIEW',
  CAN_UPDATE_ORDER_VIEW = 'CAN_UPDATE_ORDER_VIEW',
  CAN_CREATE_ORDER_VIEW = 'CAN_CREATE_ORDER_VIEW',
  CAN_DELETE_ORDER_VIEW = 'CAN_DELETE_ORDER_VIEW',
  // Order provider transaction
  CAN_READ_ORDER_TRANSACTION_LIST = 'CAN_READ_ORDER_TRANSACTION_LIST',
  CAN_UPDATE_ORDER_PROVIDER_TRANSACTION = 'CAN_UPDATE_ORDER_PROVIDER_TRANSACTION',
  CAN_CREATE_ORDER_PROVIDER_TRANSACTION = 'CAN_CREATE_ORDER_PROVIDER_TRANSACTION',
  CAN_DELETE_ORDER_PROVIDER_TRANSACTION = 'CAN_DELETE_ORDER_PROVIDER_TRANSACTION',
  // DICTIONARY
  CAN_READ_DICTIONARY = 'CAN_READ_DICTIONARY',
  // Superadmin
  CAN_READ_SUPERADMIN = 'CAN_READ_SUPERADMIN',
  CAN_READ_SUPERADMIN_LIST = 'CAN_READ_SUPERADMIN_LIST',
  CAN_CREATE_SUPERADMIN = 'CAN_CREATE_SUPERADMIN',
  CAN_UPDATE_SUPERADMIN = 'CAN_UPDATE_SUPERADMIN',
  CAN_DELETE_SUPERADMIN = 'CAN_DELETE_SUPERADMIN',
  // Invoices Provider
  CAN_READ_PROVIDER_INVOICE_LIST = 'CAN_READ_PROVIDER_INVOICE_LIST',
  CAN_READ_PROVIDER_INVOICE = 'CAN_READ_PROVIDER_INVOICE',
  CAN_READ_OWN_TENANT_PROVIDER_INVOICE_LIST = 'CAN_READ_OWN_TENANT_PROVIDER_INVOICE_LIST',
  CAN_READ_OWN_PROVIDER_INVOICE_LIST = 'CAN_READ_OWN_PROVIDER_INVOICE_LIST',
  CAN_CREATE_PROVIDER_INVOICE = 'CAN_CREATE_PROVIDER_INVOICE',
  CAN_UPDATE_PROVIDER_INVOICE = 'CAN_UPDATE_PROVIDER_INVOICE',
  CAN_UPDATE_PROVIDER_INVOICE_SHOP = 'CAN_UPDATE_PROVIDER_INVOICE_SHOP',
  CAN_UPDATE_PROVIDER_INVOICE_PAY_DATE = 'CAN_UPDATE_PROVIDER_INVOICE_PAY_DATE',
  CAN_DELETE_PROVIDER_INVOICE = 'CAN_DELETE_PROVIDER_INVOICE',
  CAN_MARK_AS_PAID_PROVIDER_INVOICE = 'CAN_MARK_AS_PAID_PROVIDER_INVOICE',
  // Invoices PO
  CAN_READ_PO_INVOICE_LIST = 'CAN_READ_PO_INVOICE_LIST',
  CAN_READ_PO_INVOICE = 'CAN_READ_PO_INVOICE',
  CAN_READ_OWN_TENANT_PO_INVOICE_LIST = 'CAN_READ_OWN_TENANT_PO_INVOICE_LIST',
  CAN_READ_OWN_PO_INVOICE_LIST = 'CAN_READ_OWN_PO_INVOICE_LIST',
  CAN_CREATE_PO_INVOICE = 'CAN_CREATE_PO_INVOICE',
  CAN_UPDATE_PO_INVOICE = 'CAN_UPDATE_PO_INVOICE',
  CAN_DELETE_PO_INVOICE = 'CAN_DELETE_PO_INVOICE',
  CAN_MARK_AS_PAID_PO_INVOICE = 'CAN_MARK_AS_PAID_PO_INVOICE',
  CAN_UPDATE_PO_INVOICE_SHOP = 'CAN_UPDATE_PO_INVOICE_SHOP',
  CAN_UPDATE_PO_INVOICE_PAY_DATE = 'CAN_UPDATE_PO_INVOICE_PAY_DATE',
  // Общие пермишены Invoices. CAN_CANCEL_INVOICE, CAN_APPROVE_INVOICE, CAN_MARK_AS_PAID_INVOICE только локальные
  CAN_APPROVE_INVOICE = 'CAN_APPROVE_INVOICE',
  CAN_CANCEL_INVOICE = 'CAN_CANCEL_INVOICE',
  CAN_MARK_AS_PAID_INVOICE = 'CAN_MARK_AS_PAID_INVOICE',
  // ORDER TYPES
  CAN_READ_ORDER_TYPE_LIST = 'CAN_READ_ORDER_TYPE_LIST',
  CAN_READ_ORDER_TYPE = 'CAN_READ_ORDER_TYPE',
  // PromotionsConfig
  CAN_READ_PROMOTIONS = 'CAN_READ_PROMOTIONS',
  CAN_UPDATE_PROMOTIONS = 'CAN_UPDATE_PROMOTIONS',
  //
  FAKE_ALLOWED = 'FAKE_ALLOWED',
  FAKE_FORBIDDEN = 'FAKE_FORBIDDEN',
  // RecommendationsConfig
  CAN_READ_RECOMMENDATIONS = 'CAN_READ_RECOMMENDATIONS',
  CAN_UPDATE_RECOMMENDATIONS = 'CAN_UPDATE_RECOMMENDATIONS',
  CAN_READ_SHOP_LIST = 'CAN_READ_SHOP_LIST',
  CAN_READ_SHOP = 'CAN_READ_SHOP',
  CAN_CREATE_SHOP = 'CAN_CREATE_SHOP',
  CAN_UPDATE_SHOP = 'CAN_UPDATE_SHOP',
  CAN_DELETE_SHOP = 'CAN_DELETE_SHOP',
}
