import { BACKEND_ROOT } from 'configs/apis';
import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { CreateTechnicianTeamRequestDTO, TechnicianTeamResponseDTO } from 'typings/dto/technicianTeam';
import ModelActionsService from '../ModelActionsService';
import ITechnicianTeamActionsService from './ITechnicianTeamActionsService';
import { batch } from 'react-redux';

export default class TechnicianTeamActionsServiceImpl
  extends ModelActionsService<TechnicianTeam, CreateTechnicianTeamRequestDTO, TechnicianTeamResponseDTO>
  implements ITechnicianTeamActionsService
{
  public getByDepartments = async (ids: string[]) => {
    this.modelStorageActions.startLoading();
    const path = `${BACKEND_ROOT}/${PATH_BACKEND_PART.technicianTeam.root}/${PATH_BACKEND_PART.technicianTeam.byDepartments}`;
    const response = await this.requestList({ ids }, undefined, path);
    batch(() => {
      this.setList(response.data);
      this.modelStorageActions.stopLoading();
    });
  };

  public getMyTeamOfferedList = (orderId: string) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/accept/teams`;
    return this.modelApiService.getList(url, this.modelMapper).then((res) => res.data);
  };
}
