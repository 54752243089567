import React, { useMemo, useState } from 'react';
import { FormControlLabel, InputAdornment, Radio, RadioGroup, Stack, SxProps, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Theme } from '@mui/material/styles';
import { useSelector } from 'storage';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import useLocales from 'hooks/useLocales';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import ILanguageService from 'services/language/ILanguageService';
import useDI from 'hooks/useDI';
import StringUtils from 'utils/String';
import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';
import useRequestHandler from 'hooks/useRequestHandler';
import IPermissionService from 'services/permission/IPermissionService';
import NumberUtils from 'utils/Number';
import SearchIcon from '@mui/icons-material/Search';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';
import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import CaptionButton from 'components/ui/buttons/CaptionButton';
import ModelUtils from 'utils/models/ModelUtils';
import ServiceOrderedAddCustomBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedAddCustomBlock';
import OrderSessionServiceCategoryGroup from 'components/_dashboardPagesFeatures/order/add/session/services/add/OrderSessionServiceCategoryGroup';
import { PRICE_VALUE_TYPE } from '../../../../../../../typings/models/price.enum';

type Props = {
  orderSession: OrderContractSessionData | null;

  submitHandler: (dto: CreateServiceOrderedRequestDTO) => any | Promise<any>;
  submitHandlerNoNavigate: (dto: CreateServiceOrderedRequestDTO) => any | Promise<any>;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  currency: ILanguageService.CurrencyCode;
  regionId: string;
  brandId: string;
  permissionConfig: IPermissionService.PermissionConfigs;
  closeAddBlock: VoidFunction;
};

function ServiceOrderedSessionAddBlock({
  closeAddBlock,
  submitHandler,
  submitHandlerNoNavigate,

  orderSession,
  currency,
  regionId,
  brandId,
  permissionConfig,
}: Props) {
  const { translate } = useLocales();
  const handleFormErrors = useSnackbarErrorHandler();
  const { services, storageActions, statefulUtils } = useDI();
  const { permissions, currentUser } = useSelector((state) => state.auth);
  const [baseService, setBaseService] = useState<ServiceOrdered | null>(null);
  const [canAddCatalogService, canAddCustomService] = useMemo(() => {
    const canAddCatalogService = permissions.serviceOrderedSessionBusinessPermissionCheck(
      FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_FROM_CATALOG,
      null,
      orderSession,
      permissionConfig
    );
    const canAddCustomService = permissions.serviceOrderedSessionBusinessPermissionCheck(
      FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_CUSTOM,
      null,
      orderSession,
      permissionConfig
    );
    return [canAddCatalogService, canAddCustomService];
  }, []);
  const [isCustom, setIsCustom] = useState(!canAddCatalogService);
  const amIOwnerOfOrder = orderSession?.tenant.id === currentUser?.tenant.id;
  const isAssignPO = false;

  const { state: priceOptionsState, isLoading: isPriceLoading } = useRequestHandler({
    initialValue: { customServicesCommission: 0, prices: [] },
    action: () => storageActions.models.priceAny.getPricesForOrderSession({ regionId, brandId, orderId: orderSession?.id }),
    instantRequest: true,
    actionName: 'priceRequest',
  });
  const [searchValue, setSearchValue] = useState('');
  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const valueIsTooLowText = translate('errors.valueIsTooSmall');
  const [validationSchema, initialValues] = useMemo(() => {
    const validationSchema = Yup.object().shape({
      service: Yup.mixed().test({ message: fieldIsRequiredText, test: (v) => isCustom || Boolean(v) }),
      name: Yup.string().trim(fieldIsRequiredText).required(fieldIsRequiredText),
      included: Yup.string(),
      notIncluded: Yup.string(),
      quantity: Yup.number().min(1, valueIsTooLowText).required(fieldIsRequiredText),
      priceClientValue: Yup.number()
        .min(0, valueIsTooLowText)
        .test({
          message: translate('entities.pricelist.currencyDecimalNumberExceededValidationError'),
          test: (v) => NumberUtils.getDecimalCount(v || 0) <= 2,
        })
        .required(fieldIsRequiredText),
      isWorkEstimationCustom: Yup.boolean(),
      workEstimateMin: Yup.number().test({
        message: translate('errors.minimumNumberValueRequired'),
        test: (value, context) => !context.parent.isWorkEstimationCustom || Boolean(value),
      }),
    });

    const initialValues = {
      name: baseService ? baseService.name : '',
      included: baseService ? baseService.included : '',
      notIncluded: baseService ? baseService.notIncluded : '',
      quantity: 1,
      priceClientValue: baseService && baseService.price ? baseService.price.initialValue : 0,
      productPrice: baseService ? baseService.productPrice : 0,
      clientPrice: baseService && baseService.price ? baseService.price.clientPrice : null,
      isWorkEstimationCustom: baseService ? Boolean(baseService.workEstimation) : false,
      workEstimateMin: baseService && baseService.workEstimation !== undefined ? baseService.workEstimation.durationMinutes : 0,

      isCustom, // Чтобы enableReinitialize отслеживал изменения
      service: baseService, // Чтобы enableReinitialize отслеживал изменения
      customServiceTitle: '', // Для отображения заголовка формы при добавлении кастомной услуги на странице создания заказа,
    };

    return [validationSchema, initialValues];
  }, [baseService?.id, isCustom]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      let priceForProductPricePercent = 0;
      if (values.clientPrice && values.clientPrice.value && values.clientPrice.type === PRICE_VALUE_TYPE.percent) {
        priceForProductPricePercent = values.clientPrice.value * values.productPrice * 0.01 * values.quantity;
      }
      try {
        setSubmitting(true);
        const dto: CreateServiceOrderedRequestDTO = {
          name: values.name,
          custom: values.isCustom,
          paid: false,
          quantity: values.quantity,
          includedText: values.included,
          notIncludedText: values.notIncluded,
          technicalName: baseService ? baseService.technicalName : StringUtils.generateUUIDv4(),
          price: {
            currency,
            discount: baseService ? baseService.price.discount : { type: 'PERCENT', value: 0 },
            value:
              (values.clientPrice && values.clientPrice.type !== PRICE_VALUE_TYPE.percent) || values.isCustom
                ? values.priceClientValue
                : priceForProductPricePercent,
            catalogPrice: values.clientPrice,
          },
          productPrice: values.clientPrice?.type !== PRICE_VALUE_TYPE.percent ? null : values.productPrice,
          paymentType: undefined,
          catalogId: baseService ? baseService.catalogId : undefined,
          orderId: orderSession?.id || '',
          workEstimation: values.isWorkEstimationCustom
            ? { durationMinutes: values.workEstimateMin }
            : baseService
            ? baseService.workEstimation
            : undefined,
          linkedEntities: [],
          // Конфиг опционален и если его не передать при добавлении услуги в заказ, то услуга будет добавлена без promotion
          // Этот кейс сейчас используется только для кастомных услуг, у них нет promotion, по этому передаем undefined
          promotion: baseService ? baseService.promotion : undefined,
        };
        return submitHandler(dto);
      } catch (error) {
        handleFormErrors({ callback: () => setSubmitting(false), error });
      }
    },
  });

  const currencySymbol = services.language.getCurrencySymbol(currency);
  const { values, isSubmitting } = formState;
  const { priceClientValue, quantity, isWorkEstimationCustom } = values;

  const technicianCommission = useMemo(() => {
    const commissionValue = values.isCustom
      ? (priceClientValue / 100) * (100 - priceOptionsState.customServicesCommission)
      : isAssignPO && amIOwnerOfOrder
      ? baseService?.price.platformOperatorReward || 0
      : baseService?.price.providerValue || 0;
    return statefulUtils.money.toString(commissionValue * quantity);
  }, [priceOptionsState.customServicesCommission, priceClientValue, baseService?.id, quantity]);

  const isCustomChangeHandler = (value: boolean) => {
    setBaseService(null);
    setIsCustom(value);
  };

  const customServiceBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    formState.setFieldTouched('name', true);
    formState.setFieldValue('customServiceTitle', event.target.value);
  };
  const deleteHandler = async (serviceOrderedId: string) => {
    if (!orderSession) return;
    await storageActions.models.serviceOrdered.deleteFromOrderSession(orderSession.id, serviceOrderedId);
    await storageActions.models.orderSession.getOrderSession(orderSession.id);
  };

  const isServiceSelected = isCustom ? true : Boolean(baseService);

  const distinctCategories: Array<ServiceCategoryWithServices> =
    ServiceOrderedUtils.servicesToServiceCategoryWithServicesWithServiceNameFilter(priceOptionsState.prices, searchValue);

  return (
    <>
      {canAddCatalogService && canAddCustomService && (
        <RadioGroup value={isCustom} onChange={(event) => isCustomChangeHandler(event.target.value === 'true')}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ backgroundColor: (theme) => theme.palette.grey[200], p: 1.2, borderRadius: 1, spacing: { xs: 0, md: 5 } }}
          >
            <FormControlLabel value={false} label={translate('entities.service.type.native')} control={<Radio size="small" />} />
            <FormControlLabel value={true} label={translate('entities.service.type.custom')} control={<Radio size="small" />} />
          </Stack>
        </RadioGroup>
      )}
      {!isCustom && isPriceLoading && <LoaderComponent />}
      {!isCustom && !isPriceLoading && (
        <>
          <TextFieldMemorized
            formState={formState}
            fieldName="searchValue"
            submitOnBlur
            size="small"
            fullWidth
            placeholder={translate('fields.search')}
            autoFocus
            onChange={(event) => setSearchValue(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Stack
            spacing={1.5}
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 1,
              overflow: { xs: 'auto', sm: 'inherit' },
              pt: 3,
              pr: 4.5,
              pl: 4.5,
              pb: 10,
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h6" align="left">
                {translate('entities.service.catalog')}
              </Typography>
              <CaptionButton onClick={closeAddBlock}>{translate('buttons.backToOrder')}</CaptionButton>
            </Stack>
            {distinctCategories.map((category, index) => {
              return (
                <OrderSessionServiceCategoryGroup
                  key={index}
                  index={index}
                  categoryName={category.categoryName}
                  prices={category.services as Price[]}
                  orderSession={orderSession}
                  currency={currency}
                  closeAddBlock={closeAddBlock}
                  submitHandlerNoNavigate={submitHandlerNoNavigate}
                  permissionConfig={permissionConfig}
                  deleteHandler={deleteHandler}
                />
              );
            })}
          </Stack>
        </>
      )}
      <ServiceOrderedAddCustomBlock
        isCustom={isCustom}
        formState={formState}
        customServiceBlurHandler={customServiceBlurHandler}
        currencySymbol={currencySymbol}
        quantity={quantity}
        priceClientValue={priceClientValue}
        amIOwnerOfOrder={amIOwnerOfOrder}
        isAssignPO={isAssignPO}
        technicianCommission={technicianCommission}
        isServiceSelected={isServiceSelected}
        isWorkEstimationCustom={isWorkEstimationCustom}
        isSubmitting={isSubmitting}
        cancelHandler={closeAddBlock}
      />
    </>
  );
}

export default React.memo(ServiceOrderedSessionAddBlock, (pp, np) => ModelUtils.checkEquality(pp.orderSession, np.orderSession));
