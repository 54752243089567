import { Box, Divider, Typography } from "@mui/material";

type Props = {
  text: string
}

export default function DividerWithText({ text }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Divider sx={{ flex: 1 }} />
      <Typography textTransform="lowercase" variant="body2" color="text.secondary" mx={2}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1 }} />
    </Box>
  );
}