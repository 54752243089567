import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import IDictionaryActionsService from 'services/storageModelActions/dictionary/IDictionaryActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import { batch as reduxBatch } from 'react-redux';
import { DistributionCityDTO } from 'typings/dto/distributionCity';

export default class DictionaryActionsServiceImpl
  extends ModelActionsService<Dictionary, DictionaryDTO, DictionaryDTO>
  implements IDictionaryActionsService
{
  async getAllDictionariesForRecommendations(tenantId: string): Promise<void> {
    const url = PATH_BACKEND.dictionary.recommendations + `/${tenantId}`;

    this.storageDispatch(this.modelStorageActions.startAllLoading());
    await reduxBatch(async () => {
      try {
        const response = await this.entityApiService.getWithCredentials<DictionaryDTO[]>(url).then((dicts) => {
          return dicts.map((d) => this.modelMapper.responseDTOToModel(d));
        });
        if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setAll({ data: response }));
        else console.warn(`Get list request ${this.modelStorageName} is outdated`);
      } catch (error) {
        if (!this.areActionsOutdated) {
          console.error(error);
          this.storageDispatch(this.modelStorageActions.setError(error));
        }
      }
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopAllLoading());
    });
  }

  async getAllDictionariesForPromotions(tenantId: string): Promise<void> {
    const url = PATH_BACKEND.dictionary.promotions + `/${tenantId}`;

    this.storageDispatch(this.modelStorageActions.startAllLoading());
    await reduxBatch(async () => {
      try {
        const response = await this.entityApiService.getWithCredentials<DictionaryDTO[]>(url).then((dicts) => {
          return dicts.map((d) => this.modelMapper.responseDTOToModel(d));
        });
        if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setAll({ data: response }));
        else console.warn(`Get list request ${this.modelStorageName} is outdated`);
      } catch (error) {
        if (!this.areActionsOutdated) {
          console.error(error);
          this.storageDispatch(this.modelStorageActions.setError(error));
        }
      }
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopAllLoading());
    });
  }

  async getAllWareCategories(): Promise<WareCategoryDto[]> {
    const url = PATH_BACKEND.dictionary.category;
    return this.entityApiService.getWithCredentials<WareCategoryDto[]>(url);
  }

  async getAllContractTypes(): Promise<ContractTypeDTO[]> {
    const url = PATH_BACKEND.dictionary.contractType;
    return this.entityApiService.getWithCredentials<ContractTypeDTO[]>(url);
  }

  async getAllDistributionCities(): Promise<DistributionCityDTO[]> {
    const url = PATH_BACKEND.distributionCity.root;
    return this.entityApiService.getWithCredentials<DistributionCityDTO[]>(url);
  }
}
