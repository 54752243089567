import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';
import CreditCardOffRoundedIcon from '@mui/icons-material/CreditCardOffRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DomainDisabledRoundedIcon from '@mui/icons-material/DomainDisabledRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LabelRoundedIcon from '@mui/icons-material/LabelRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRounded from '@mui/icons-material/NavigateBeforeRounded';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ReportOffRoundedIcon from '@mui/icons-material/ReportOffRounded';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditIconMui from '@mui/icons-material/Edit';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {
  AddCircleRounded,
  BubbleChartRounded,
  ChevronRightRounded,
  DescriptionRounded,
  FaceRounded,
  FolderRounded,
  Lightbulb,
  TableRestaurantRounded,
} from '@mui/icons-material';
import AccountBalanceRounded from '@mui/icons-material/AccountBalanceRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import WorkOffRoundedIcon from '@mui/icons-material/WorkOffRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import { SxProps, Theme } from '@mui/material';
import { ORDER_TYPE_ICON_NAME } from 'typings/models/order/orderType.enum';
import { ORDER_LABEL_TYPE_ICON_NAME } from '../../../typings/models/order/orderLabel.enum';
import ProductionQuantityLimitsRoundedIcon from '@mui/icons-material/ProductionQuantityLimitsRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import DownloadRoundedIcon from '@mui/icons-material/Download';
import SvgIconStyled from '../images/SvgIconStyled';
import ReplyIcon from '@mui/icons-material/Reply';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AutoAwesomeMotionRoundedIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// TODO сюда перенести иконки из главного меню (они в конфиге назначаются)
// И остальные бы иконки по системе собрать в одном месте
export const CartIcon = createIconComponent('ic_cart.svg');
export const ParentCommentIcon = SubdirectoryArrowRightIcon;
export const ReplyArrowIcon = ReplyIcon;
export const MessagesIcon = createIconComponent('ic_messages.svg');
export const PhoneIcon = LocalPhoneRoundedIcon;
export const EmailIcon = EmailRoundedIcon;
export const ExternalLinkIcon = LaunchRoundedIcon;
export const FileIcon = FileCopyRoundedIcon;
export const CopyIcon = FileCopyRoundedIcon;
export const CloseIcon = CloseRoundedIcon;
export const PlusIcon = AddRoundedIcon;
export const PlusCircleIcon = AddCircleRounded;
export const CancelIcon = CancelRoundedIcon;
export const MinusIcon = RemoveRoundedIcon;
export const ErrorIcon = ErrorRoundedIcon;
export const WhatsAppIcon = createIconComponent('ic_whatsapp.svg');
export const TelegramIcon = createIconComponent('ic_telegram.svg');
export const StarIcon = StarRoundedIcon;
export const CheckIcon = DoneRoundedIcon;
export const DateIcon = CalendarTodayRoundedIcon;
export const NotificationsIcon = NotificationsRoundedIcon;
export const NotificationsOffIcon = NotificationsOffRoundedIcon;
export const WarningIcon = WarningRoundedIcon;
export const InfoIcon = InfoRoundedIcon;
export const FolderIcon = FolderRounded;
export const RegionIcon = PinDropRoundedIcon;
export const ImageIcon = ImageRoundedIcon;
export const UserOffIcon = PersonOffRoundedIcon;
export const ListIcon = FormatListBulletedRoundedIcon;
export const SupportIcon = SupportAgentRoundedIcon;
export const DeleteIcon = DeleteOutlineIcon;
export const CardIcon = CreditCardRoundedIcon;
export const CardOffIcon = CreditCardOffRoundedIcon;
export const UserPlusIcon = PersonAddAltRoundedIcon;
export const UserMinusIcon = PersonRemoveRoundedIcon;
export const GeoPointIcon = PlaceRoundedIcon;
export const PersonSearchIcon = PersonSearchRoundedIcon;
export const SearchFailedIcon = SearchOffRoundedIcon;
export const ClockIcon = AccessTimeFilledRoundedIcon;
export const WorkTimerIcon = WorkHistoryRoundedIcon;
export const MoneyIcon = PaymentsRoundedIcon;
export const CalendarOffIcon = EventBusyRoundedIcon;
export const TruckIcon = LocalShippingIcon;
export const HiddenIcon = VisibilityOffRoundedIcon;
export const LoginIcon = LoginRoundedIcon;
export const PersonIcon = PersonRoundedIcon;
export const DotIcon = FiberManualRecordIcon;
export const EditIcon = EditIconMui;
export const PercentIcon = PercentRoundedIcon;
export const PlayIcon = PlayArrowRoundedIcon;
export const PauseIcon = PauseRoundedIcon;
export const TaskIcon = TaskRoundedIcon;
export const AddDocumentIcon = NoteAddRoundedIcon;
export const PeopleIcon = PeopleAltRoundedIcon;
export const EditDocumentIcon = EditNoteRoundedIcon;
export const ArrowRight = ArrowForwardRoundedIcon;
export const DescriptionIcon = DescriptionRoundedIcon;
export const CaseOffIcon = WorkOffRoundedIcon;
export const ChevronRightIcon = ChevronRightRounded;
export const ExpandMoreArrow = ExpandMoreIcon;
export const HelpIcon = HelpRoundedIcon;
export const BulbIcon = Lightbulb;
export const BubbleChartIcon = BubbleChartRounded;
export const DownloadIcon = DownloadRoundedIcon;
export const FilterIcon = FilterAltRoundedIcon;
export const NextRowIcon = NavigateNextRoundedIcon;
export const PrevRowIcon = NavigateBeforeRounded
export const FullscreenIcon = FullscreenRoundedIcon;
export const FullscreenExitIcon = FullscreenExitRoundedIcon

export const ServiceProviderAssignedEventIcon = GroupAddRoundedIcon;
export const NewOrderAssignedEventIcon = WorkRoundedIcon;
export const MessageReceivedEventIcon = TextsmsRoundedIcon;
export const RatingUpdatedEventIcon = StarIcon;
export const ReportUpdatedEventIcon = TaskIcon;
export const CrossInterfaceCollapseIcon = LastPageRoundedIcon;

export const OrderStatusChangedToWorkInProgressEventIcon = ConstructionRoundedIcon;
export const OrderStatusChangedToPausedEventIcon = PauseIcon;
export const OrderStatusChangedToCancelledEventIcon = CaseOffIcon;
export const OrderStatusChangedToWorkFinishedEventIcon = HowToRegRoundedIcon;
export const OrderStatusChangedToCompletedEventIcon = CheckIcon;
export const OrderWorkStartedEventIcon = OrderStatusChangedToWorkInProgressEventIcon;
export const OrderWorkFinishedEventIcon = EventAvailableRoundedIcon;
export const ProblemAppearedEventIcon = ReportRoundedIcon;
export const ProblemSolvedEventIcon = ReportOffRoundedIcon;
export const DateUpdatedEventIcon = DateIcon;
export const TaskUpdatedEventIcon = AddTaskRoundedIcon;
export const RegionOffIcon = DomainDisabledRoundedIcon;

export const OrderStatusIcon = PanoramaFishEyeRoundedIcon;
export const OrderStatusWorkStartedIcon = BuildCircleRoundedIcon;
export const OrderBrandIcon = CopyrightRoundedIcon;
export const OrderRegionIcon = RegionIcon;
export const OrderServiceProviderIcon = PeopleIcon;
export const OrderHandymanIcon = AccountBoxRoundedIcon;
export const OrderCreationDateIcon = DateIcon;
export const OrderVisitDateIcon = DateIcon;
export const OrderReclamationVisitDateIcon = EventRepeatIcon;
export const OrderTaskIcon = ListAltRoundedIcon;
export const OrderClientsNameIcon = PersonIcon;
export const OrderClientsPhoneIcon = PhoneIcon;
export const OrderClientsAddressIcon = GeoPointIcon;
export const OrderLabelIcon = LabelRoundedIcon;
export const OrderManagersIcon = ManageAccountsRoundedIcon;
export const OrderCustomFieldIcon = FiberManualRecordRoundedIcon;
export const OrderDepartmentFieldIcon = BusinessRoundedIcon;
export const OrderNumberFieldIcon = TagRoundedIcon;
export const OrderAccessDeniedIcon = VpnKeyRoundedIcon;
export const OrderEnterpriseIcon = AccountBalanceRounded;
export const OrderServicePackageIcon = AutoAwesomeMotionRoundedIcon
export const LinkedEntityLinkIcon = ChevronRightIcon;
export const LockIcon = createIconComponent('ic_lock.svg');
export const OrderOpenIcon = ReadMoreRoundedIcon
export const ArchiveIcon = Inventory2RoundedIcon
export const PersonCancelIcon = createIconComponent('ic_person_cancel.svg')
export const AddShoppingCartIcon = AddShoppingCartRoundedIcon
export const WorkOffIcon = WorkOffRoundedIcon
export const ArrowBackIcon = ArrowBackRoundedIcon;

// Order types
export const orderTypeIconMap = {
  [ORDER_TYPE_ICON_NAME.defaultOrderType]: ConstructionRoundedIcon,
  [ORDER_TYPE_ICON_NAME.warrantyOrderType]: WorkspacePremiumRoundedIcon,
  [ORDER_TYPE_ICON_NAME.measureOrderType]: StraightenRoundedIcon,
  [ORDER_TYPE_ICON_NAME.reclamationOrderType]: WarningRoundedIcon,
};

// Order label types
export const orderLabelTypeIconMap = {
  [ORDER_LABEL_TYPE_ICON_NAME.problemWithOrder]: ProductionQuantityLimitsRoundedIcon,
  [ORDER_LABEL_TYPE_ICON_NAME.problemWithDocuments]: DescriptionRounded,
  [ORDER_LABEL_TYPE_ICON_NAME.productType]: TableRestaurantRounded,
  [ORDER_LABEL_TYPE_ICON_NAME.workWithClient]: FaceRounded,
};

// Util

function createIconComponent(iconName: string): React.FC<{ sx?: SxProps<Theme> }> {
  return ({ sx }: { sx?: SxProps<Theme> }) => <SvgIconStyled sx={sx} src={'/icons/' + iconName} />;
}
