import {
  CancelInvoiceRequestDTO,
  ConfirmInvoiceRequestDTO,
  CreateAdditionalTransactionRequestDTO,
  CreateInvoiceRequestDTO,
  CreateOrderTransactionRequestDTO,
  DeleteAdditionalTransactionRequestDTO,
  DeleteOrderTransactionRequestDTO,
  EditAdditionalTransactionRequestDTO,
  ExportInvoicePeriodRequestDTO,
  ExportInvoiceRequestDTO,
  GenerateInvoicesRequestDTO,
  GetInvoiceAdditionalTransactionListRequestDTO,
  GetInvoiceCustomerListRequestDTO,
  GetInvoiceOrderListRequestDTO,
  GetInvoiceProviderListRequestDTO,
  GetPartyOfInvoiceForFilter,
  InvoiceCustomerDTO,
  InvoiceCustomersTenantsListResponseDTO,
  InvoiceProviderDTO,
  InvoiceResponseDTO,
  InvoiceTransactionDTO,
  OrderTransactionsDTO,
  UpdateInvoicePayDateRequestDTO,
  UpdateInvoiceShopRequestDTO,
} from 'typings/dto/invoice';
import ModelActionsService from '../ModelActionsService';
import IInvoiceActionsService from './IInvoiceActionsService';
import { Invoice, InvoiceCustomer, InvoiceProvider, InvoiceTransaction, OrderTransactions } from 'typings/models/invoice';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import NavigateBackendUtils from 'utils/NavigateBackend';
import InvoiceMapper from 'utils/mappers/Invoice';
import { FUNDS_TRANSACTION_COUNTERPARTY_TYPE } from 'typings/models/fundsTransaction.enum';

export default class InvoiceActionsServiceImpl
  extends ModelActionsService<Invoice, CreateInvoiceRequestDTO, InvoiceResponseDTO>
  implements IInvoiceActionsService
{
  updateInvoiceShop(invoice: Invoice, dto: UpdateInvoiceShopRequestDTO): Promise<void> {
    const counterpartyType =
      invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoice.id}/${PATH_BACKEND_PART.invoice.shop}`;
    return this.entityApiService.postWithCredentials(path, dto, false);
  }

  updateInvoicePayDate(invoice: Invoice, dto: UpdateInvoicePayDateRequestDTO): Promise<void> {
    const counterpartyType =
      invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoice.id}/${PATH_BACKEND_PART.invoice.payDate}`;
    return this.entityApiService.postWithCredentials(path, dto, false);
  }

  /** @throws `BackendResponseError` */
  public getInvoiceProvidersList = async (
    dto: GetInvoiceProviderListRequestDTO,
    filter?: LocationSearchObject
  ): Promise<EntityListData<InvoiceProvider>> => {
    filter = this.getDefaultSortFilter(filter, 'createdAt');
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.invoiceProviders}/${counterpartyType}`;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);
    fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);

    const responseContainer = await this.entityApiService.getListWithCredentials<InvoiceProviderDTO>(fullPath);
    return {
      data: responseContainer.data.map((this.modelMapper as InvoiceMapper).providersResponseDTOtoModel),
      pagination: responseContainer.pagination,
    };
  };

  /** @throws `BackendResponseError` */
  public getInvoiceCustomersTenantsList = async (
    filter?: LocationSearchObject
  ): Promise<EntityListData<InvoiceCustomersTenantsListResponseDTO>> => {
    const path = PATH_BACKEND.invoicePO.customersTenants;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    const fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);

    const responseContainer = await this.entityApiService.getListWithCredentials<InvoiceCustomersTenantsListResponseDTO>(fullPath);
    return {
      data: responseContainer.data,
      pagination: responseContainer.pagination,
    };
  };

  /** @throws `BackendResponseError` */
  public getInvoiceCustomersList = async (
    dto: GetInvoiceCustomerListRequestDTO,
    filter?: LocationSearchObject
  ): Promise<EntityListData<InvoiceCustomer>> => {
    filter = this.getDefaultSortFilter(filter, 'createdAt');
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po + '/tenants/' + dto.customerTenantId;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.invoiceCustomers}/${counterpartyType}`;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    const fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);

    const responseContainer = await this.entityApiService.getListWithCredentials<InvoiceCustomerDTO>(fullPath);
    return {
      data: responseContainer.data.map((this.modelMapper as InvoiceMapper).customersResponseDTOtoModel),
      pagination: responseContainer.pagination,
    };
  };

  /** @throws `BackendResponseError` */
  public getOrderTransactionsList = async (
    dto: GetInvoiceOrderListRequestDTO,
    filter?: LocationSearchObject
  ): Promise<EntityListData<OrderTransactions>> => {
    filter = this.getDefaultSortFilter(filter, 'createdAt');
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.invoiceOrders}/${counterpartyType}`;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);
    fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);

    const responseContainer = await this.entityApiService.getListWithCredentials<OrderTransactionsDTO>(fullPath);
    return {
      data: responseContainer.data.map((this.modelMapper as InvoiceMapper).orderResponseDTOtoModel),
      pagination: responseContainer.pagination,
    };
  };

  /** @throws `BackendResponseError` */
  public getAdditionalTransactionsList = async (
    dto: GetInvoiceAdditionalTransactionListRequestDTO,
    filter?: LocationSearchObject
  ): Promise<EntityListData<InvoiceTransaction>> => {
    filter = this.getDefaultSortFilter(filter, 'createdAt');
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.invoiceAdditionalTransactions}/${counterpartyType}`;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    const fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);

    const responseContainer = await this.entityApiService.getListWithCredentials<InvoiceTransactionDTO>(fullPath);
    return {
      data: responseContainer.data.map((this.modelMapper as InvoiceMapper).transactionResponseDTOToModel),
      pagination: responseContainer.pagination,
    };
  };

  /** @throws `BackendResponseError` */
  public createInvoiceAdditionalTransaction = async ({
    invoiceId,
    transactionTypeId,
    description,
    amount,
    counterpartyType: type,
  }: CreateAdditionalTransactionRequestDTO): Promise<void> => {
    const counterpartyType =
      type === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider ? PATH_BACKEND_PART.invoice.provider : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoiceId}/${PATH_BACKEND_PART.invoice.additionalTransactions}`;
    await this.entityApiService.postWithCredentials(path, { transactionTypeId, description, amount });
  };

  /** @throws `BackendResponseError` */
  public updateInvoiceAdditionalTransaction = async ({
    invoiceId,
    transactionId,
    description,
    amount,
    counterpartyType: type,
  }: EditAdditionalTransactionRequestDTO): Promise<void> => {
    const counterpartyType =
      type === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider ? PATH_BACKEND_PART.invoice.provider : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoiceId}/${PATH_BACKEND_PART.invoice.additionalTransactions}/${transactionId}`;
    await this.entityApiService.putWithCredentials(path, { description, amount });
  };

  /** @throws `BackendResponseError` */
  public deleteInvoiceAdditionalTransaction = async ({
    invoiceId,
    transactionId,
    counterpartyType: type,
  }: DeleteAdditionalTransactionRequestDTO): Promise<void> => {
    const counterpartyType =
      type === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider ? PATH_BACKEND_PART.invoice.provider : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoiceId}/${PATH_BACKEND_PART.invoice.additionalTransactions}/${transactionId}`;
    await this.entityApiService.deleteWithCredentials(path);
  };

  /** @throws `BackendResponseError` */
  public createInvoiceOrderTransaction = async ({
    invoiceId,
    orderIds,
    counterpartyType: type,
  }: CreateOrderTransactionRequestDTO): Promise<void> => {
    const counterpartyType =
      type === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider ? PATH_BACKEND_PART.invoice.provider : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoiceId}/${PATH_BACKEND_PART.invoice.orderTransactions}`;
    await this.entityApiService.postWithCredentials(path, orderIds, false);
  };

  /** @throws `BackendResponseError` */
  public deleteInvoiceOrderTransaction = async ({
    invoiceId,
    invoiceOrderIds,
    counterpartyType: type,
  }: DeleteOrderTransactionRequestDTO): Promise<void> => {
    const counterpartyType =
      type === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider ? PATH_BACKEND_PART.invoice.provider : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoiceId}/${PATH_BACKEND_PART.invoice.orderTransactions}`;
    await this.entityApiService.delete(path, {
      body: JSON.stringify(invoiceOrderIds),
      credentials: 'include',
    });
  };

  /** @throws `BackendResponseError` */
  public exportInvoice = async (invoiceId: string, dto: ExportInvoiceRequestDTO): Promise<void> => {
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoiceId}/${PATH_BACKEND_PART.invoice.export}`;
    await this.entityApiService.postWithCredentials(path, dto, false);
  };

  /** @throws `BackendResponseError` */
  public exportInvoicePeriod = async (dto: ExportInvoicePeriodRequestDTO): Promise<void> => {
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${PATH_BACKEND_PART.invoice.exportPeriod}`;
    await this.entityApiService.postWithCredentials(path, dto, false);
  };

  /** @throws `BackendResponseError` */
  public confirmInvoice = async (dto: ConfirmInvoiceRequestDTO): Promise<void> => {
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${dto.invoiceId}/${PATH_BACKEND_PART.invoice.confirm}`;
    await this.entityApiService.postWithCredentials(path, undefined, false);
  };

  public markAsPaidInvoice = async (invoice: Invoice): Promise<void> => {
    const counterpartyType =
      invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${invoice.id}/${PATH_BACKEND_PART.invoice.markAsPaid}`;
    await this.entityApiService.postWithCredentials(path, undefined, false);
  };

  /** @throws `BackendResponseError` */
  public cancelInvoice = async (dto: CancelInvoiceRequestDTO): Promise<void> => {
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${dto.invoiceId}/${PATH_BACKEND_PART.invoice.cancel}`;
    await this.entityApiService.postWithCredentials(path, undefined, false);
  };

  /** @throws `BackendResponseError` */
  public generateInvoices = async (dto: GenerateInvoicesRequestDTO): Promise<EntityWithName[]> => {
    const counterpartyType =
      dto.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider
        ? PATH_BACKEND_PART.invoice.provider
        : PATH_BACKEND_PART.invoice.po;
    const path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.invoice.root}/${counterpartyType}/${PATH_BACKEND_PART.invoice.generate}`;
    const invoiceAmount = await this.entityApiService.postWithCredentials<EntityWithName[]>(path, dto, true);
    return invoiceAmount;
  };

  /** @throws `BackendResponseError` */
  public getInvoiceProviderForFilter = async (
    dto: GetPartyOfInvoiceForFilter,
    filter?: LocationSearchObject
  ): Promise<EntityListData<InvoiceProvider>> => {
    filter = this.getDefaultSortFilter(filter, 'createdAt');
    const path = PATH_BACKEND.invoice.filterProviders;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);
    fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);

    const responseContainer = await this.entityApiService.getListWithCredentials<InvoiceProviderDTO>(fullPath);
    return {
      data: responseContainer.data.map((this.modelMapper as InvoiceMapper).providersResponseDTOtoModel),
      pagination: responseContainer.pagination,
    };
  };

  /** @throws `BackendResponseError` */
  public getInvoiceCustomerForFilter = async (
    dto: GetPartyOfInvoiceForFilter,
    filter?: LocationSearchObject
  ): Promise<EntityListData<InvoiceCustomer>> => {
    filter = this.getDefaultSortFilter(filter, 'createdAt');
    const path = PATH_BACKEND.invoice.filterCustomers;
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);
    fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);

    const responseContainer = await this.entityApiService.getListWithCredentials<InvoiceCustomerDTO>(fullPath);
    return {
      data: responseContainer.data.map((this.modelMapper as InvoiceMapper).customersResponseDTOtoModel),
      pagination: responseContainer.pagination,
    };
  };
}
