import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

export default function UnauthorizedLayout({ children }: Props) {
  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ height: 77, display: { md: 'none', xs: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
        <img src="/images/marya_logo.png" alt="" />
      </Box>
      <Box
        sx={{
          backgroundImage: 'url(/images/auth/background.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          minHeight: { xs: 'calc(100% - 77px)', md: '100%' },
          display: 'flex',
          backgroundColor: '#232121AB',
          backgroundBlendMode: 'overlay',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
